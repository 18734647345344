<template>
  <div
    class="badge-base"
    :class="{'badge-tab': documentType !== null}"
  >
    {{ value !== null ? value : unverifiedDocumentLength }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VERIFIED_STATUS_INDEX } from '@/store/helpers/request/transformers/transformDocuments';

export default {
  props: {
    documentType: {
      type: String,
      default: null,
    },
    value: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      documents: 'documents/all',
    }),
    unverifiedDocumentLength() {
      return this.documents.filter(this.filterDocuments).length;
    },
  },
  methods: {
    filterDocuments(document) {
      const docVerifyStatus = document.fields[VERIFIED_STATUS_INDEX].verifyStatus;
      const documentUnverified = docVerifyStatus === 'DOC_UNVERIFIED';

      // If the document type is set, allow unverified documents of this type.
      const docType = document.documentType;
      if (this.documentType !== null) {
        return documentUnverified && docType === this.documentType;
      }

      // If the document type was not set, allow unverified documents.
      return documentUnverified;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/styles/_variables.scss';

  .badge-base {
    display:inline-block;
    padding:3px 7px;
    line-height:1;
    text-align:center;
    white-space:nowrap;
    vertical-align:middle;
    border-radius:6px;
    font-size:$txt-h6-size;
    font-weight: $bold;

    // style for badge in the verification-navbar
    color: $txt-col-primary;
    background-color: $bg-body;
  }

  // style for badge in the verification-heading tabs e.g. "performance"
  .badge-tab {
    background-color: $tab-col-active;
    color: $txt-col-primary-neg;
    margin-left: 10px;
    margin-bottom: 2px;
  }
</style>
