<template>
  <table class="contained-table">
    <tr :style="{zIndex: '1', position: 'relative'}">
      <th>
        Name
        <span
          class="icon-container column-header-icon"
          @click="sortBy('name')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'name' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th v-if="enableUserSettingsUplift">
        Email
        <span
          class="icon-container column-header-icon"
          @click="sortBy('email')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'email' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th v-if="enableUserSettingsUplift">
        Role
        <span
          class="icon-container column-header-icon"
          @click="sortBy('role')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'role' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th>
        Status
        <span
          class="icon-container column-header-icon"
          @click="sortBy('account_status')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'account_status' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
    </tr>

    <tr
      v-for="user in users"
      :key="user.id"
    >
      <td>
        <a
          href="#"
          class="cell-link"
          @click.prevent="onUserNamePressed(user)"
        >{{ user.name }}</a>
      </td>
      <td v-if="enableUserSettingsUplift">
        {{ user.email }}
      </td>
      <td v-if="enableUserSettingsUplift">
        <user-role-pill :user-permission-level="user.permissions" />
      </td>
      <td class="active-status">
        {{ getAccountStatusDisplay(user.account_status) }}
      </td>
    </tr>
  </table>
  <p
    v-if="!users.length"
    class="hint"
  >
    No users to display.
  </p>
</template>
<script>
import { getAccountStatusDisplay } from '@/store/helpers/mapping/permissions';
import { TriangleIcon } from '@zhuowenli/vue-feather-icons';
import UserRolePill from '@/components/users/UserRolePill.vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    TriangleIcon,
    UserRolePill,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
    order: {
      type: String,
      default: null,
    },
  },
  emits: ['userSelected', 'updateOrder'],
  data() {
    return {
      selectedOrderByColumn: null,
    };
  },
  computed: {
    ...mapGetters({
      orderByColumn: 'users/orderByColumnName',
      orderByDirection: 'users/orderByDirection',
    }),
    enableUserSettingsUplift() {
      return FEATURE_FLAGS.USER_SETTINGS_UPLIFT;
    },
    sortIconClass() {
      const columnName = this.selectedOrderByColumn;
      return {
        'sort-show': this.orderByColumn === columnName,
        'sort-asc': this.orderByDirection === 'asc',
        'sort-desc': this.orderByDirection === 'desc',
      };
    },
  },
  methods: {
    ...mapActions({
      sortUsers: 'users/sortUsers',
    }),
    onUserNamePressed(user) {
      this.$log.info('onUserNamePressed', user);
      this.$emit('userSelected', user.id);
    },
    getAccountStatusDisplay,
    async sortBy(columnName) {
      this.selectedOrderByColumn = columnName;
      // By default, sort column by ascending
      const initialSortDirection = 'asc';
      const secondarySortDirection = 'desc';
      let order = { columnName, direction: initialSortDirection };
      // If column has already been sorted once, sort by descending
      if (this.orderByColumn === columnName && this.orderByDirection === initialSortDirection) {
        order = { columnName, direction: secondarySortDirection };
      } else if (this.orderByColumn === columnName && this.orderByDirection === secondarySortDirection) {
        // Reset order
        order = null;
      }
      await this.sortUsers(order);
    },
  },
};
</script>
<style lang="scss" scoped>
.active-status {
  color: $col-grey;
}
.icon-container:hover {
  background-color: transparent;
}
</style>
