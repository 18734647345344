import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

/**
 * Sort array by date property.
 * @param {*} direction 0:None, 1:ASC, 2:DESC
 */
const dateSort = (arrayToSort, columnIndex, direction, dateFormat = 'MM/DD/YYYY') => {
  dayjs.extend(customParseFormat);

  // Set a default date to make sure nulls are always last.
  let defaultDate = new Date(253402300800000); // Date very far in the future
  if (direction === 2) {
    defaultDate = new Date(1);
  }

  arrayToSort.sort((row1, row2) => {
    const r1 = row1.fields[columnIndex].text;
    const r2 = row2.fields[columnIndex].text;
    const r1Date = r1 ? dayjs(r1, dateFormat) : defaultDate;
    const r2Date = r2 ? dayjs(r2, dateFormat) : defaultDate;
    const result = r1Date - r2Date;

    // DESC
    if (direction === 2) {
      if (result > 0) {
        return -1;
      }
      return (result < 0) ? 1 : 0;
    }

    // ASC
    if (result > 0) {
      return 1;
    }
    return (result < 0) ? -1 : 0;
  });
};

export default dateSort;
