import Api from '@/store/helpers/api';
import { logger } from '@/store/logger';
import orderBy from 'lodash.orderby';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from './helpers/storeState';
import { userRole } from './helpers/mapping/roles';

const sorters = {
  name: (user) => user.name.toLowerCase(),
  email: (user) => user.email.toLowerCase(),
  role: (user) => user.role,
  account_status: (user) => user.account_status,
};

const store = {
  namespaced: true,
  state: {
    ...storeState,
    users: [],
    search: '',
    orderBy: {
      columnName: null,
      direction: null,
    },
  },

  getters: {
    ...storeStateGetters,
    users: (state) => state.users,
    orderByColumnName: (state) => state.orderBy?.columnName,
    orderByDirection: (state) => state.orderBy?.direction,
    search: (state) => state.search,
  },

  mutations: {
    ...storeStateMutations,
    SET_USERS(state, users) {
      state.users = users;
    },
    SET_SEARCH(state, search) {
      state.search = search;
    },
    SET_ORDER_BY(state, order) {
      state.orderBy = order;
    },
    ADD_USER(state, user) {
      state.users.push(user);
    },
  },

  actions: {
    ...storeStateActions,
    init: async ({ dispatch, commit }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      try {
        logger.debug('Initializing user store');
        const response = await dispatch('getUsers', null);
        logger.debug('Got users: ', response.users);
        commit('SET_USERS', response.users);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    /**
     * Get users request with search and filters applied.
     */
    getUsers: async ({ rootGetters, getters }) => {
      logger.debug(`Getting users -- search string: ${getters.search}`,
        `--- order -- ${getters.orderByColumnName} ${getters.orderByDirection}`);
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get('users', { search: getters.search, order: 'name' })
        .then((response) => {
          // Add role to users
          let usersWithRoles = response.users.map((user) => ({ ...user, role: userRole(user.permissions).display }));
          // Sort users
          if (getters.orderByColumnName) {
            usersWithRoles = orderBy(usersWithRoles, [sorters[getters.orderByColumnName]], [getters.orderByDirection ?? 'asc']);
          }
          return { users: usersWithRoles };
        });
    },
    refetchUsers: async ({ dispatch, commit }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      try {
        logger.debug('Refetching users');
        const { users } = await dispatch('getUsers');
        commit('SET_USERS', users);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        logger.debug('Failed to fetch and update users in store', e);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    /**
     * Create new user and add it to the list of users.
     */
    createUser: async ({ rootGetters, dispatch, commit }, { name, email }) => {
      logger.debug('Creating user', name, email);
      try {
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        await (new Api(process.env, rootGetters['authenticate/idToken']))
          .post('users', {
            name, email, user_group: 1, user_management_scope: 1,
          });
        logger.debug('User successfully created. Refetching users to update store.');
        await dispatch('refetchUsers');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        logger.debug('Something went wrong while trying to create user', e);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    /**
     * Update user by giving the current version of the user and the update version.
     * Only sends update request if there is at least one thing to update.
     */
    updateUser: async ({ rootGetters, dispatch, commit }, { user, updatedUser }) => {
      logger.debug('Updating user; old user:', user, '; updated:', updatedUser);
      try {
        if (
          user.permissions === updatedUser.permissions
          && user.account_status === updatedUser.account_status
        ) {
          return;
        }
        const body = {
          permissions: updatedUser.permissions,
          account_status: updatedUser.account_status,
        };
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        await (new Api(process.env, rootGetters['authenticate/idToken']))
          .post(`users/${user.id}`, body)
          .then((res) => {
            logger.debug('User updated successfully', res);
          });
        await dispatch('refetchUsers');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        logger.debug('Something went wrong while trying to update user', e);
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    sortUsers: async ({ commit, dispatch }, order) => {
      try {
        logger.debug('Sorting users - ', order);
        commit('SET_ORDER_BY', order);
        await dispatch('refetchUsers');
      } catch (e) {
        logger.debug('Something went wrong while trying to sort users', e);
        throw e;
      }
    },
    /**
     * Update users using the search parameters.
     */
    searchUsers: async ({ dispatch, commit }, search) => {
      try {
        logger.debug('Searching users', search);
        commit('SET_SEARCH', search);
        await dispatch('refetchUsers');
      } catch (e) {
        logger.debug('Something went wrong while trying to filter users by a search string', e);
        throw e;
      }
    },
  },
};

export default store;
