import saveAs from 'file-saver';
import { logger } from '@/store/logger';

const saveB64ZipFile = (b64Str, filename) => {
  logger.debug('Processing data:', b64Str);
  const binaryString = window.atob(b64Str);
  const binaryLen = binaryString.length;

  const arrayBuffer = new ArrayBuffer(binaryLen);
  const bytesArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryLen; i++) {
    bytesArray[i] = binaryString.charCodeAt(i);
  }
  const bb = new Blob([arrayBuffer], { type: 'application/octet-stream' });
  bb.name = `${filename}.zip`;
  bb.lastModifiedDate = new Date();

  saveAs(bb, `${filename}.zip`);
};

const saveJsonFile = (jsonData, fileName) => {
  const jsonStringified = JSON.stringify(jsonData);
  const blob = new Blob([jsonStringified], { type: 'text/plain' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');
  link.download = `${fileName}.json`;
  link.click();
};
const saveFile = (fileName, data) => {
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.click();
};

export {
  saveJsonFile,
  saveB64ZipFile,
  saveFile,
};
