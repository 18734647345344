import tdrToEntityNameMetrics from '@/store/helpers/metrics/tdrToEntityNameMetrics';
import {
  allowedStates, storeState, storeStateActions, storeStateGetters, storeStateMutations,
} from '@/store/helpers/storeState';
import Api from '@/store/helpers/api';
import { buildPatchSaveTableBody } from '@/store/helpers/metricTableState/stateChange';
import { extractionProgressMappingToBackend } from '@/store/helpers/mapping/extractionProgresses';
import PATCHDocumentRequest from '@/store/helpers/mockResponses/PATCHDocumentRequest';
import { logger } from '@/store/logger';
import GETDocumentRequest from './helpers/mockResponses/GETDocumentRequest_1';

const initialState = () => ({
  storeStatus: allowedStates.IS_BLANK,
  autoSaveStatus: allowedStates.IS_BLANK,
  confirmDocumentStatus: allowedStates.IS_BLANK,
  selectedMetricIndex: -1,
  fieldIndexBeingSelected: -1,
  canRedo: false,
  canVerifyRow: false,
  canVerifyColumn: false,
  document: null,
});

const store = {
  namespaced: true,
  state: {
    ...storeState,
    ...initialState(),
  },

  getters: {
    ...storeStateGetters,
    autoSaveStatus: (state) => state.autoSaveStatus,
    confirmDocumentStatus: (state) => state.confirmDocumentStatus,
    grouping: (state) => state.grouping,
    canRedo: (state) => state.canRedo,
    canVerifyRow: (state) => state.canVerifyRow,
    canVerifyColumn: (state) => state.canVerifyColumn,

    document: (state) => state.document,
  },

  mutations: {
    ...storeStateMutations,
    RESET(state) {
      Object.assign(state, initialState());
    },
    SET_AUTO_SAVE_STATUS(state, status) {
      state.autoSaveStatus = status;
    },
    SET_CONFIRM_DOCUMENT_STATUS(state, status) {
      state.confirmDocumentStatus = status;
    },
    SET_CAN_REDO(state, canRedo) {
      state.canRedo = canRedo;
    },
    SET_CAN_VERIFY_ROW_COLUMN(state, { canVerifyRow, canVerifyColumn }) {
      state.canVerifyRow = canVerifyRow;
      state.canVerifyColumn = canVerifyColumn;
    },
  },

  actions: {
    ...storeStateActions,
    reset: ({ commit }) => {
      commit('SET_PROP', { key: 'storeStatus', value: allowedStates.IS_BLANK });
      commit('SET_PROP', { key: 'autoSaveStatus', value: allowedStates.IS_BLANK });
      commit('SET_PROP', { key: 'confirmDocumentStatus', value: allowedStates.IS_BLANK });
      commit('SET_PROP', { key: 'documentRequestId', value: '' });
      commit('SET_PROP', { key: 'selectedMetricIndex', value: -1 });
      commit('SET_PROP', { key: 'fieldIndexBeingSelected', value: -1 });
      commit('SET_PROP', { key: 'canRedo', value: false });
      commit('SET_PROP', { key: 'canVerifyRow', value: false });
      commit('SET_PROP', { key: 'canVerifyColumn', value: false });
      commit('SET_PROP', { key: 'document', value: null });
    },
    init: async ({ dispatch, commit }, { documentRequestId }) => {
      try {
        logger.debug('verifyDocument init');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        const docReq = await dispatch('getDocumentRequest', { documentRequestId });
        logger.debug('documentRequestId:', documentRequestId, 'docReq:', docReq);
        logger.debug('Setting document:', docReq);
        commit('SET_PROP', { key: 'document', value: docReq });
        logger.debug('Setting store statuses');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        commit('SET_AUTO_SAVE_STATUS', allowedStates.IS_READY);
        commit('SET_CONFIRM_DOCUMENT_STATUS', allowedStates.IS_READY);
        logger.debug('Computing entity name metrics');
        const entityNameMetrics = tdrToEntityNameMetrics(docReq.grouping, docReq.tables);
        logger.debug('entityNameMetrics:', entityNameMetrics);
        dispatch('entityNameMetrics/init', entityNameMetrics, { root: true });
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    getDocumentRequest: ({ getters, rootGetters }, { documentRequestId }) => {
      logger.debug(`Getting documentrequest ${documentRequestId}, offline: ${getters.offline}`);
      if (rootGetters['documentRequest/offline']) {
        return new Promise(((resolve) => {
          setTimeout(resolve, 500, GETDocumentRequest());
        }));
      }
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get(`documentrequest/${documentRequestId}`);
    },
    autoSaveWithoutChanges: async ({ commit }) => {
      // Trigger auto-save to start loading so that we can hook off the change to a 'successful state'
      logger.debug('autoSaveWithoutChanges triggered');
      commit('SET_AUTO_SAVE_STATUS', allowedStates.IS_LOADING);
      setTimeout(() => {
        commit('SET_AUTO_SAVE_STATUS', allowedStates.IS_READY);
      });
    },
    saveTable: async ({
      getters, commit, rootGetters, dispatch,
    }, { tableIdx, stateChanges, breadcrumbIdx }) => {
      dispatch('authenticate/attemptAuthFromStorage', null, { root: true });
      logger.debug('Saving metrics for table', tableIdx, 'stateChanges:', stateChanges);
      const tableGroupId = getters.document?.grouping.groups[breadcrumbIdx].groupIdentifier;
      const colHeaders = getters.document?.tables[tableIdx].columnHeaders;
      const body = buildPatchSaveTableBody(stateChanges, tableGroupId, colHeaders);
      logger.debug('StateChanges body:', body);
      commit('SET_AUTO_SAVE_STATUS', allowedStates.IS_LOADING);

      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .patch(`documentrequest/${getters.document.documentRequestId}/datapoints`, body)
        .then((r) => r)
        .catch((e) => {
          commit('SET_AUTO_SAVE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },
    /* Set document request to verified */
    postDocumentRequestIsVerifiedStatus: async ({ commit, dispatch, rootGetters }, { documentRequestId, isVerified }) => {
      logger.debug('Posting isVerified for:', documentRequestId, `offline:[${rootGetters['documentRequest/offline']}]`);
      commit('SET_CONFIRM_DOCUMENT_STATUS', allowedStates.IS_LOADING);
      let request;
      if (!rootGetters['documentRequest/offline']) {
        const stage = extractionProgressMappingToBackend.EXT_DONE;
        request = dispatch('postDocumentRequestState', { documentRequestId, isVerified, stage });
      } else {
        request = new Promise(((resolve) => {
          setTimeout(resolve, 500, PATCHDocumentRequest());
        }));
      }

      request.then((r) => {
        commit('SET_CONFIRM_DOCUMENT_STATUS', allowedStates.IS_READY);
        return r;
      })
        .catch((e) => {
          commit('SET_CONFIRM_DOCUMENT_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });

      return request;
    },
    postDocumentRequestState: ({ rootGetters }, { documentRequestId, isVerified, stage }) => {
      const path = `documentrequest/${documentRequestId}/state`;
      const body = {
        stage,
        is_verified: isVerified,
      };
      logger.debug(`  path:${path} body:`, body);

      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post(path, body);
    },
  },
};

export default store;
