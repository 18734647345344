import { logger } from '@/store/logger';
import {
  storeState, storeStateGetters, storeStateMutations, allowedStates,
} from './helpers/storeState';
import Api from './helpers/api';
import { backendToSettings, getDefaultSettings } from './helpers/request/transformers/transformSettings';
import GETSettings from './helpers/mockResponses/GETSettings';
import dateFormats from './helpers/mapping/dateFormats';
import { permissionGroups, userHasPermission } from './helpers/mapping/permissions';

const initialUserEntityPermissions = {};
const store = {
  namespaced: true,
  state: {
    ...storeState,
    language: '', // or 'en-US'
    defaultCurrency: '', // not currently utilised for default effector creation
    isTrial: false,
    limitedUploads: false,
    user: null,
    userEntityPermissions: initialUserEntityPermissions,
  },

  getters: {
    ...storeStateGetters,
    language: (state) => state.language,
    dateFormat: (state) => (state.language in dateFormats ? dateFormats[state.language] : dateFormats.DEFAULT),
    isTrial: (state) => (state.isTrial),
    limitedUploads: (state) => (state.limitedUploads),
    user: (state) => (state.user),
    userEntityPermissions: (state) => (state.userEntityPermissions),
  },

  mutations: {
    ...storeStateMutations,
    RESET_ALL(state) {
      logger.debug('Resetting all localisation state');
      state.language = '';
      state.defaultCurrency = '';
      state.isTrial = false;
      state.limitedUploads = false;
      state.user = null;
      state.userEntityPermissions = initialUserEntityPermissions;
    },
  },

  actions: {
    reset({ commit }) {
      commit('RESET_ALL');
    },

    lazyInit: async ({ commit, dispatch, getters }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      if (getters.language.length) {
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return Promise.resolve(getters.language);
      }

      try {
        const rawSettings = await dispatch('getSettings');
        const settings = backendToSettings(rawSettings);
        logger.debug('transformed settings:', settings);
        commit('SET_PROP', { key: 'language', value: settings.defaultLanguage });
        commit('SET_PROP', { key: 'defaultCurrency', value: settings.defaultCurrency });
        commit('SET_PROP', { key: 'isTrial', value: settings.isTrial });
        commit('SET_PROP', { key: 'limitedUploads', value: settings.limitedUploads });
        commit('SET_PROP', { key: 'user', value: settings.user });
        commit('SET_PROP', {
          key: 'userEntityPermissions',
          value: Object.fromEntries(
            permissionGroups.Entities.map(({ permission }) => [permission, userHasPermission(permission, settings.user.permissions)]),
          ), // e.g., { CAN_CREATE_UPDATE_ENTITIES: true, CAN_DELETE_ENTITIES }
        });
        logger.debug('Set entity permissions', getters.userEntityPermissions);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);

        return settings;
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    initWithDefault: ({ commit }) => {
      logger.debug('initialising settings with defaults');
      const settings = getDefaultSettings();
      commit('SET_PROP', { key: 'language', value: settings.defaultLanguage });
      // Set user entity permissions as false
      commit('SET_PROP', {
        key: 'userEntityPermissions', value: Object.fromEntries(permissionGroups.Entities.map(({ permission }) => [permission, false])),
      });
      commit('SET_STORE_STATUS', allowedStates.IS_READY);
    },
    getSettings: ({ rootGetters }) => {
      const isOffline = rootGetters['documentRequest/offline'];
      logger.debug(`Getting settings, offline: ${isOffline}`);

      if (!isOffline) {
        return (new Api(process.env, rootGetters['authenticate/idToken']))
          .get('settings');
      }
      return new Promise(((resolve) => {
        setTimeout(resolve, 500, GETSettings());
      }));
    },
    /**
     * Check if the logged in user has permissions.
     * @param {String} permission permission to check.
     * @returns {Boolean} true if the logged in user has the asked permission.
     */
    checkPermission: async ({ getters, dispatch }, permission) => {
      await dispatch('lazyInit');
      const userPermission = getters.user.permissions;
      logger.debug('Checking permission', permission, 'for user with permission', userPermission);
      return userHasPermission(permission, userPermission);
    },
  },
};

export default store;
