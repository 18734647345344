import clonedeep from 'lodash.clonedeep';
import { ref } from 'vue';
import objHasKey from '@/store/helpers/objHasKey';
import { logger } from '@/store/logger';

const stateChangeTypes = {
  AKA_VALUE: 'AKA_VALUE',
  CONFIDENCE: 'CONFIDENCE',
  CURRENCY_VALUE: 'CURRENCY_VALUE',
  ENTITY_LINK: 'ENTITY_LINK',
  LOCATION: 'LOCATION',
  ROW: 'ROW',
  VALUE: 'VALUE',
  VERIFY: 'VERIFY',
};

const cudTypes = {
  CREATE: 'C',
  UPDATE: 'U',
  DELETE: 'D',
};

const preservedTableCurrency = ref('AUTO');

const createDefaultEffectorsForMetricType = (metricType) => {
  if (metricType === 'ENTITY_NAME') {
    return { akas: [] };
  }
  if (metricType === 'CURRENCY') {
    return { currency: preservedTableCurrency.value === 'AUTO' ? 'USD' : preservedTableCurrency.value };
  }
  return {};
};

const createDefaultRowFromColumnHeaders = (columnHeaders, entityName) => {
  logger.debug('createDefaultRowFromColumnHeaders');
  return columnHeaders.map((ch, idx) => ({
    datapoints: [
      {
        confidence: 0,
        effectors: createDefaultEffectorsForMetricType(ch.metricType),
        nodeId: null,
        page: null,
        value: idx === 0 && entityName !== '' ? entityName : null,
      },
    ],
    fin: ch.fin,
    id: '#####',
    isVerified: false,
    metricType: ch.metricType,
    validators: ch.validators,
  }));
};

const getIntRange = (rangeStart, rangeEnd) => Array.from(new Array(rangeEnd - rangeStart), (x, i) => i + rangeStart);

const realiseStateChange = (stateChange, rows, metrics, columnHeaders) => {
  if (stateChange.type === stateChangeTypes.ROW) {
    if (stateChange.cud === cudTypes.CREATE) {
      const rowRangeStart = metrics.length;
      const rowRangeEnd = metrics.length + columnHeaders.length;
      const newMetrics = (
        stateChange.newMetrics ? stateChange.newMetrics : createDefaultRowFromColumnHeaders(columnHeaders, stateChange.newVal)
      );
      const newRow = getIntRange(rowRangeStart, rowRangeEnd);
      logger.debug('Creating new row:', newRow, newMetrics);
      rows.splice(stateChange.rowIdx, 0, newRow);
      metrics.push(...newMetrics);
      return;
    }
    if (stateChange.cud === cudTypes.DELETE) {
      rows.splice(stateChange.rowIdx, 1);
      return;
    }
  }

  const metricIndex = rows[stateChange.rowIdx][stateChange.colIdx];
  const editingMetrics = metrics;
  if (stateChange.type === stateChangeTypes.VERIFY) {
    editingMetrics[metricIndex].isVerified = stateChange.newVal;
  } else if (stateChange.type === stateChangeTypes.VALUE) {
    editingMetrics[metricIndex].datapoints[0].value = stateChange.newVal;
  } else if (stateChange.type === stateChangeTypes.CONFIDENCE) {
    editingMetrics[metricIndex].datapoints[0].confidence = stateChange.newVal;
  } else if (stateChange.type === stateChangeTypes.LOCATION) {
    const { datapoints } = editingMetrics[metricIndex];
    // Update both attributes at once to reduce re-running hooked computed properties
    datapoints[0] = {
      ...datapoints[0],
      annotations: clonedeep(stateChange.newVal),
      annotationClear: stateChange.newVal.length === 0, // To not display annotation after unlink
    };
  } else if (stateChange.type === stateChangeTypes.CURRENCY_VALUE) {
    editingMetrics[metricIndex].datapoints[0].effectors.currency = stateChange.newVal;
  } else if (stateChange.type === stateChangeTypes.AKA_VALUE) {
    editingMetrics[metricIndex].datapoints[0].effectors.akas = clonedeep(stateChange.newVal);
  } else if (stateChange.type === stateChangeTypes.ENTITY_LINK) {
    editingMetrics[metricIndex].linkedEntity = clonedeep(stateChange.newVal);
  } else {
    throw Error(`stateChangeType not implemented ${stateChange.type}, ${stateChange.cud}`);
  }
};

/* State Change Creation functions */

const createRowStateChange = (rowIdx, newVal, newMetrics = null) => ({
  type: stateChangeTypes.ROW,
  cud: cudTypes.CREATE,
  rowIdx,
  newVal,
  newMetrics,
});

const deleteRowStateChange = (rowIdx, oldVal) => ({
  type: stateChangeTypes.ROW,
  cud: cudTypes.DELETE,
  rowIdx,
  oldVal,
});

const updateVerifyStateChange = (metricId, rowIdx, colIdx, oldVal, newVal, singleChange = false) => ({
  type: stateChangeTypes.VERIFY,
  cud: cudTypes.UPDATE,
  metricId,
  rowIdx,
  colIdx,
  oldVal,
  newVal,
  singleChange,
});

const updateValueStateChange = (metricId, rowIdx, colIdx, oldVal, newVal, singleChange = false) => ({
  type: stateChangeTypes.VALUE,
  cud: cudTypes.UPDATE,
  metricId,
  rowIdx,
  colIdx,
  oldVal,
  newVal,
  singleChange,
});

const updateLocationStateChange = (metricId, rowIdx, colIdx, oldVal, newVal, singleChange = false) => ({
  type: stateChangeTypes.LOCATION,
  cud: cudTypes.UPDATE,
  metricId,
  rowIdx,
  colIdx,
  oldVal,
  newVal,
  singleChange,
});

const updateConfidenceStateChange = (metricId, rowIdx, colIdx, oldVal, newVal, singleChange = false) => ({
  type: stateChangeTypes.CONFIDENCE,
  cud: cudTypes.UPDATE,
  metricId,
  rowIdx,
  colIdx,
  oldVal,
  newVal,
  singleChange,
});

const updateCurrencyValueStateChange = (metricId, rowIdx, colIdx, oldVal, newVal, singleChange = false) => ({
  type: stateChangeTypes.CURRENCY_VALUE,
  cud: cudTypes.UPDATE,
  metricId,
  rowIdx,
  colIdx,
  oldVal,
  newVal,
  singleChange,
});

const updateSelectedEntityValueStateChange = (metricId, rowIdx, colIdx, oldVal, newVal) => ({
  type: stateChangeTypes.ENTITY_LINK,
  cud: cudTypes.UPDATE,
  metricId,
  rowIdx,
  colIdx,
  oldVal,
  newVal,
});

const updateAkaValueStateChange = (metricId, rowIdx, colIdx, oldVal, newVal, singleChange = false) => ({
  type: stateChangeTypes.AKA_VALUE,
  cud: cudTypes.UPDATE,
  metricId,
  rowIdx,
  colIdx,
  oldVal,
  newVal,
  singleChange,
});

/* Request builders */

const buildCreatedRow = (stateChange, tableGroupId, colHeaders) => {
  logger.debug('building created row:', stateChange, 'tgid:', tableGroupId, 'colHeaders:', colHeaders);
  const metrics = {};
  colHeaders.forEach((ch, idx) => {
    // Add a new metric only if one doesn't exist for fin. (E.g. AKA has same fin as ENTITY_NAME metric)
    if (!objHasKey(metrics, ch.fin)) {
      metrics[ch.fin] = {
        id: idx,
        fin: ch.fin,
      };
    }
    if (idx === 0) {
      metrics[ch.fin].value = stateChange.newVal;
    }
  });
  return {
    rowIndex: stateChange.rowIdx,
    groupIdentifier: tableGroupId,
    tempRowId: 999999, // Temp row id not used until we sync newly created cell ids with the backend
    metrics,
  };
};

const buildDeletedRow = (stateChange, tableGroupId) => ({
  rowIndex: stateChange.rowIdx,
  groupIdentifier: tableGroupId,
});

const buildPatchSaveTableBody = (stateChanges, tableGroupId, colHeaders) => {
  const createdRows = [];
  const deletedRows = [];
  const updatedMetrics = {};
  stateChanges.forEach((stateChange) => {
    if (stateChange.type === stateChangeTypes.ROW && stateChange.cud === cudTypes.CREATE) {
      createdRows.push(buildCreatedRow(stateChange, tableGroupId, colHeaders));
    } else if (stateChange.type === stateChangeTypes.ROW && stateChange.cud === cudTypes.DELETE) {
      deletedRows.push(buildDeletedRow(stateChange, tableGroupId));
    } else if (stateChange.type === stateChangeTypes.VALUE && stateChange.cud === cudTypes.UPDATE) {
      if (!objHasKey(updatedMetrics, stateChange.metricId)) {
        updatedMetrics[stateChange.metricId] = {
          id: stateChange.metricId,
          value: stateChange.newVal,
        };
      } else {
        updatedMetrics[stateChange.metricId].value = stateChange.newVal;
      }
    } else if (stateChange.type === stateChangeTypes.LOCATION && stateChange.cud === cudTypes.UPDATE) {
      if (!objHasKey(updatedMetrics, stateChange.metricId)) {
        updatedMetrics[stateChange.metricId] = {
          id: stateChange.metricId,
          annotations: clonedeep(stateChange.newVal),
        };
      } else {
        updatedMetrics[stateChange.metricId].annotations = clonedeep(stateChange.newVal);
      }
    } else if (stateChange.type === stateChangeTypes.CURRENCY_VALUE && stateChange.cud === cudTypes.UPDATE) {
      if (!objHasKey(updatedMetrics, stateChange.metricId)) {
        updatedMetrics[stateChange.metricId] = {
          id: stateChange.metricId,
          effectors: { currency: stateChange.newVal },
        };
      } else {
        updatedMetrics[stateChange.metricId].effectors = { currency: stateChange.newVal };
      }
    } else if (stateChange.type === stateChangeTypes.ENTITY_LINK && stateChange.cud === cudTypes.UPDATE) {
      if (!objHasKey(updatedMetrics, stateChange.metricId)) {
        updatedMetrics[stateChange.metricId] = {
          id: stateChange.metricId,
          entityLinkId: stateChange.newVal.selectedEntityId,
        };
      } else {
        updatedMetrics[stateChange.metricId].entityLinkId = stateChange.newVal.selectedEntityId;
      }
    } else if (stateChange.type === stateChangeTypes.CONFIDENCE && stateChange.cud === cudTypes.UPDATE) {
      if (!objHasKey(updatedMetrics, stateChange.metricId)) {
        updatedMetrics[stateChange.metricId] = {
          id: stateChange.metricId,
          confidence: stateChange.newVal,
        };
      } else {
        updatedMetrics[stateChange.metricId].confidence = stateChange.newVal;
      }
    } else if (stateChange.type === stateChangeTypes.AKA_VALUE && stateChange.cud === cudTypes.UPDATE) {
      if (!objHasKey(updatedMetrics, stateChange.metricId)) {
        updatedMetrics[stateChange.metricId] = {
          id: stateChange.metricId,
          effectors: { akas: stateChange.newVal },
        };
      } else {
        updatedMetrics[stateChange.metricId].effectors = { akas: stateChange.newVal };
      }
    } else if (stateChange.type === stateChangeTypes.VERIFY && stateChange.cud === cudTypes.UPDATE) {
      if (!objHasKey(updatedMetrics, stateChange.metricId)) {
        updatedMetrics[stateChange.metricId] = {
          id: stateChange.metricId,
          isVerified: stateChange.newVal,
        };
      } else {
        updatedMetrics[stateChange.metricId].isVerified = stateChange.newVal;
      }
    } else {
      throw Error(`Unsupported stateChangeType or cud: ${stateChange.type}/${stateChange.cud}`);
    }
  });

  return {
    created: createdRows,
    deleted: deletedRows,
    updated: Object.values(updatedMetrics),
  };
};

export {
  stateChangeTypes,
  cudTypes,
  preservedTableCurrency,
  realiseStateChange,
  createRowStateChange,
  deleteRowStateChange,
  updateAkaValueStateChange,
  updateConfidenceStateChange,
  updateCurrencyValueStateChange,
  updateSelectedEntityValueStateChange,
  updateLocationStateChange,
  updateValueStateChange,
  updateVerifyStateChange,
  buildPatchSaveTableBody,
};
