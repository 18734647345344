<template>
  <table class="contained-table">
    <tr :style="{zIndex: '1', position: 'relative'}">
      <th class="table-col-0">
        <checkbox-input
          id="entitySelectAll"
          :model-value="isAllRelationshipsSelected"
          @update:modelValue="onToggleSelectAll"
        />
      </th>
      <th>
        <span>Name</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('name')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'name' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>AKA</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('akaCount')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'akaCount' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th>
        <span>Type</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('metricIdentifierDisplayName')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'metricIdentifierDisplayName' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>Last Edit</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('timeUpdated')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'timeUpdated' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
    </tr>
    <tr
      v-for="relatedEntity in relatedEntities"
      :key="relatedEntity.interDocumentEntityId"
    >
      <td class="table-col-0">
        <checkbox-input
          :id="`entitySelect-${relatedEntity.interDocumentEntityId}`"
          :model-value="relatedEntity.relationships.some((r) => rowSelectionData[r.interDocumentRelationshipId] === true)"
          @update:modelValue="onToggleSelectRow(relatedEntity.relationships.map((relationship) => relationship.interDocumentRelationshipId))"
        />
      </td>
      <td class="name-column">
        <router-link
          :to="{ name: 'entity-overview', params: { name: relatedEntity.name }}"
          class="cell-link"
        >
          {{ relatedEntity.name }}
        </router-link>
      </td>
      <td class="text-centre aka-count-column">
        {{ relatedEntity.akaCount }}
      </td>
      <td>
        <div
          v-if="relatedEntity.relationships.length > 0"
          class="type-pills"
        >
          <pill
            v-for="(displayName, idx) in entityRelationshipTypes(relatedEntity)"
            :key="idx"
            :label="displayName"
          />
        </div>
        <span
          v-else
          class="missing-info-placeholder"
        >
          Not defined
        </span>
      </td>
      <td class="text-centre last-edit-column">
        <span v-if="relatedEntity.timeUpdated">{{ formatDate(relatedEntity.timeUpdated) }}</span>
        <span
          v-else
          class="missing-info-placeholder"
        >-</span>
      </td>
    </tr>
  </table>
  <p
    v-if="!relatedEntities.length"
    class="hint"
  >
    No entity relationships to display.
  </p>
</template>
<script>
import dayjs from 'dayjs';
import Pill from '@/components/general/Pill.vue';
import { TriangleIcon } from '@zhuowenli/vue-feather-icons';
import { mapGetters, mapActions } from 'vuex';
import uniq from 'lodash.uniq';
import CheckboxInput from '../../forms/CheckboxInput.vue';

export default {
  components: {
    Pill,
    CheckboxInput,
    TriangleIcon,
  },
  props: {
    relatedEntities: {
      type: Array,
      required: true,
    },
    rowSelectionData: {
      type: Object,
      required: true,
    },
  },
  emits: ['updateRowSelection'],
  data() {
    return {
      selectedOrderByColumn: null,
    };
  },
  computed: {
    ...mapGetters({
      orderBy: 'entityRelationships/orderBy',
    }),
    sortIconClass() {
      const columnName = this.selectedOrderByColumn;
      return {
        'sort-show': this.orderByColumn === columnName,
        'sort-asc': this.orderByDirection === 'asc',
        'sort-desc': this.orderByDirection === 'desc',
      };
    },
    isAllRelationshipsSelected() {
      const ids = this.relationshipsIds;
      const selectedRelationships = Object.keys(this.rowSelectionData).filter((relationshipId) => this.rowSelectionData[relationshipId] === true);
      return ids.every((val) => selectedRelationships.includes(val));
    },
    orderByColumn() {
      return this.orderBy !== null ? this.orderBy.split(':')[0] : null;
    },
    orderByDirection() {
      return this.orderBy !== null ? this.orderBy.split(':')[1] : null;
    },
    relationshipsIds() {
      return this.relatedEntities.map((rE) => rE.relationships.map((r) => r.interDocumentRelationshipId)).flat(1);
    },
  },
  methods: {
    ...mapActions({
      sortRelatedEntities: 'entityRelationships/sortRelatedEntities',
    }),
    entityRelationshipTypes(entity) {
      // Return an entity's relationships types/display names without duplicates
      return uniq(entity.relationships.map((r) => r.displayName));
    },
    sortBy(columnName) {
      this.selectedOrderByColumn = columnName;
      // By default, sort column by ascending
      const initialSortDirection = 'asc';
      const secondarySortDirection = 'desc';
      let order = `${columnName}:${initialSortDirection}`;
      // If column has already been sorted once, sort by descending
      if (this.orderByColumn === columnName && this.orderByDirection === initialSortDirection) {
        order = `${columnName}:${secondarySortDirection}`;
      } else if (this.orderByColumn === columnName && this.orderByDirection === secondarySortDirection) {
        order = null; // Remove sort on the third attempt
      }
      this.sortRelatedEntities({ orderBy: order });
    },
    formatDate(datetime) {
      if (dayjs(datetime).isValid()) {
        return dayjs(datetime).format('MMM DD YYYY');
      }
      return '';
    },
    onToggleSelectAll() {
      const selection = this.relationshipsIds.reduce((ac, relationshipId) => (
        { ...ac, [relationshipId]: !this.isAllRelationshipsSelected }
      ), {});
      this.$emit('updateRowSelection', selection);
    },
    onToggleSelectRow(relationshipsIds) {
      const newRowSelections = relationshipsIds.map((rowId) => ({ [rowId]: !this.rowSelectionData[rowId] }));
      const finalObj = {};
      for (let i = 0; i < newRowSelections.length; i++) {
        Object.assign(finalObj, newRowSelections[i]);
      }
      this.$emit('updateRowSelection', finalObj);
    },
  },
};
</script>
<style lang="scss" scoped>
.type-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contained-table {
  font-size: $txt-p-size;
  margin-bottom: 55px;
  min-width: 900px;

  .name-column  {
    width: 40%;
    min-width: 360px;
  }

  .type-column, .aka-count-column, .last-edit-column {
    width: 20%;
    min-width: 180px;
  }

  td {
    vertical-align: middle;
  }

  th {
    white-space: nowrap;
    &.table-col-0 {
      z-index: 1;
      width: 58px;
    }

    .icon-container:hover {
      background-color: transparent;
    }
  }
}
</style>
