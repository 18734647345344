import { format, parseISO } from 'date-fns';
import { logger } from '@/store/logger';
import { verifyStatusMappingFromBackend, verifyStatusToDisplay } from '../../mapping/verifyStatuses';
import ValidationException from '../exceptions/ValidationException';

const verifyStatusToField = (vs) => {
  if (!(vs in verifyStatusMappingFromBackend)) {
    logger.error('Invalid verify status', vs, Object.keys(verifyStatusMappingFromBackend));
    return {
      text: 'Invalid verify status',
      verifyStatus: 'DOC_PROCESSED',
    };
  }
  const status = verifyStatusMappingFromBackend[vs];
  return {
    text: verifyStatusToDisplay[status],
    verifyStatus: status,
  };
};

const datapointToField = (dp) => {
  let toolTip;
  let text;
  let hasGreyedText;
  if (dp === null) {
    toolTip = 'This field has not been found';
    text = '';
    hasGreyedText = true;
  } else {
    toolTip = dp.is_verified ? 'This field has been verified' : 'This field has been found, but not verified';
    hasGreyedText = !dp.is_verified;
    text = dp.value;
  }

  return {
    text,
    hasGreyedText,
    toolTip,
  };
};

const VERIFIED_STATUS_INDEX = 17;

const backendToDocument = (d, index, dateFormat, documentTypes) => {
  let documentType = null;
  let reportingDate = null;
  let fundName = null;
  let fundManager = null;
  let companyName = null;
  let transactionId = null;
  let orderId = null;
  let limitedPartner = null;
  let placementAgent = null;
  let paymentDueDate = null;
  let gpName = null;
  let supplierName = null;
  let bankName = null;
  let accountNumber = null;
  if ('document_type_name' in d) {
    documentType = documentTypes[d.document_type_name];
  }
  if ('datapoints' in d) {
    if ('reporting_date' in d.datapoints) {
      reportingDate = d.datapoints.reporting_date;
      reportingDate.value = reportingDate.value ? format(parseISO(reportingDate.value), dateFormat.fnsFormat) : reportingDate.value;
    }
    if ('am:fund:general:fund_name' in d.datapoints) {
      fundName = d.datapoints['am:fund:general:fund_name'];
    }
    if ('am:fund:general:fund_name_raw' in d.datapoints) {
      fundName = d.datapoints['am:fund:general:fund_name_raw'];
    }
    if ('am:fund:general:fund_manager' in d.datapoints) {
      fundManager = d.datapoints['am:fund:general:fund_manager'];
    }
    if ('am:investments:company:portfolio_summary:company_name_raw' in d.datapoints) {
      companyName = d.datapoints['am:investments:company:portfolio_summary:company_name_raw'];
    }
    if ('ps:transaction_id' in d.datapoints) {
      transactionId = d.datapoints['ps:transaction_id'];
    }
    if ('ps:order_id' in d.datapoints) {
      orderId = d.datapoints['ps:order_id'];
    }
    if ('ps:order_id' in d.datapoints) {
      orderId = d.datapoints['ps:order_id'];
    }
    if ('am:lp:general:lp_name' in d.datapoints) {
      limitedPartner = d.datapoints['am:lp:general:lp_name'];
    }
    if ('am:subscription:placement_agent' in d.datapoints) {
      placementAgent = d.datapoints['am:subscription:placement_agent'];
    }
    if ('am:lp:capital_notice:payment_instructions:due_date' in d.datapoints) {
      paymentDueDate = d.datapoints['am:lp:capital_notice:payment_instructions:due_date'];
    }
    if ('am:gp:general:gp_name' in d.datapoints) {
      gpName = d.datapoints['am:gp:general:gp_name'];
    }
    if ('invoice:supplier_name' in d.datapoints) {
      supplierName = d.datapoints['invoice:supplier_name'];
    }
    if ('am:bank:general:bank_name' in d.datapoints) {
      bankName = d.datapoints['am:bank:general:bank_name'];
    }
    if ('am:lp:bank:account_no' in d.datapoints) {
      accountNumber = d.datapoints['am:lp:bank:account_no'];
    }
  }
  const datapointIndices = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  const fields = {
    fileName: d.file_name,
    documentType,
    reportingDate,
    paymentDueDate,
    fundName,
    fundManager,
    gpName,
    companyName,
    supplierName,
    transactionId,
    orderId,
    limitedPartner,
    placementAgent,
    bankName,
    accountNumber,
    uploadDate: d.upload_date,
    uploadBy: d.uploaded_by,
    verifyStatus: '',
    verifiedBy: d.verified_by === null ? '' : d.verified_by,
  };

  // Currently done for backwards compatibility:
  // moving to simple list of keyed objects as soon as table refactor happens
  const fieldList = (Object.values(fields)).map((f, ind) => {
    if (ind === 3 && f && f.value) {
      return {
        text: format(parseISO(f.value), dateFormat.fnsFormat),
        toolTip: format(parseISO(f.value), 'PPPp', { locale: dateFormat.fnsLocale }),
      };
    }

    if (datapointIndices.includes(ind)) {
      return datapointToField(f);
    }

    if (ind === 15) {
      return {
        text: format(parseISO(f), dateFormat.fnsFormat),
        toolTip: format(parseISO(f), 'PPPp', { locale: dateFormat.fnsLocale }),
      };
    }

    if (ind === VERIFIED_STATUS_INDEX) {
      const verifyStr = d.is_verified ? 'DOCUMENT_CLIENT_VERIFIED' : 'DOCUMENT_UNVERIFIED';
      return verifyStatusToField(verifyStr);
    }

    return {
      text: f,
    };
  });

  return {
    id: d.id,
    document_request_id: d.document_request_id,
    selected: false,
    docIndex: index,
    documentType: d.document_type_name,
    fields: fieldList,
  };
};

const backendToDocuments = (documents, dateFormat, documentTypes) => {
  if (!documents) {
    throw new ValidationException('Failed to retrieve documents');
  }
  return documents.map((d, i) => backendToDocument(d, i, dateFormat, documentTypes));
};

export { backendToDocuments, VERIFIED_STATUS_INDEX };
