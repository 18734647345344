import clonedeep from 'lodash.clonedeep';
import { logger } from '@/store/logger';
import {
  allowedStates, storeState, storeStateActions, storeStateGetters, storeStateMutations,
} from './helpers/storeState';
import Api from './helpers/api';
import { backendToDocuments } from './helpers/request/transformers/transformDocuments';
import GETDocumentRequest from './helpers/mockResponses/GETDocumentRequest';
import GETDocumentLinks from './helpers/mockResponses/GETDocumentLinks';
import DELETEDocumentRequest from './helpers/mockResponses/DELETEDocumentRequest';
import dateSort from './helpers/dateSort';

/**
 * Customize a set of headers with an array of modified headers (matched by key)
 *
 * @param {Array} headers - The array of headers ({key: string, display: string, hidden: boolean}) to be customized.
 * @param {Array} modifiedHeaders - Array of modified headers, each of which must have a matching key value
 * to an element of the headers array
 */
function customizedHeaders(headers, { modifiedHeaders = [] }) {
  modifiedHeaders.forEach((modifiedHeader) => {
    const header = headers.find((h) => h.key === modifiedHeader.key);
    header.display = modifiedHeader.display;
    header.hidden = modifiedHeader.hidden ?? false;
  });
  return headers;
}
/**
 * --- DEVELOPMENT WARNING ---
 *
 * This approach is temporary, in the long term we aim to configure the
 * document columns from our backend.
 *
 * After you update the 'tableHeaders', you also need to update:
 *   - 'backendToDocument' to map the backend date to the table headers
 *   - 'selectedDocumentRequests' computed method, to make sure the
 *     right verified column is selected (you might have to append the
 *     index)
 *
 * Note: Stick to 8 visible columns to avoid the table being too large
 *       to fit on a screen.
 */
const tableHeaders = () => {
  const DEFAULT_HEADERS = [
    { key: 'docName', display: 'PDF Name' },
    { key: 'documentType', display: 'Document Type' },
    { key: 'dueDate', display: 'Due Date' },
    { key: 'paymentDueDate', display: 'Due Date', hidden: true },
    { key: 'fund', display: 'Fund', hidden: true },
    { key: 'fundManager', display: 'Fund Manager', hidden: true },
    { key: 'gpName', display: 'Fund Manager', hidden: true },
    { key: 'companyName', display: 'Company Name', hidden: true },
    { key: 'supplierName', display: 'Supplier', hidden: true },
    { key: 'transactionId', display: 'Transaction', hidden: true },
    { key: 'orderId', display: 'Order', hidden: true },
    { key: 'limitedPartner', display: 'LP', hidden: true },
    { key: 'placementAgent', display: 'Agent', hidden: true },
    { key: 'bankName', display: 'Custodian', hidden: true },
    { key: 'accountNumber', display: 'Account number', hidden: true },
    { key: 'uploadDate', display: 'Upload Date' },
    { key: 'uploadBy', display: 'Uploaded By' },
    { key: 'verifyStatus', display: 'Verify Status' },
    { key: 'verifiedBy', display: 'Verified By' },
  ];
  const defaultHeadersByClient = (client) => {
    switch (client) {
      case 'ps':
        return customizedHeaders(clonedeep(DEFAULT_HEADERS), {
          modifiedHeaders: [
            { key: 'fund', display: 'Fund', hidden: false },
            { key: 'limitedPartner', display: 'LP', hidden: false },
            { key: 'orderId', display: 'Order ID', hidden: false },
            { key: 'dueDate', display: 'Due Date', hidden: true },
          ],
        });
      default:
        return DEFAULT_HEADERS;
    }
  };
  return {
    DEFAULT: defaultHeadersByClient(process.env.VUE_APP_CLIENT),
    am_capital_notice: [
      { key: 'docName', display: 'PDF Name' },
      { key: 'documentType', display: 'Document Type', hidden: true },
      { key: 'dueDate', display: 'Due Date' },
      { key: 'paymentDueDate', display: 'Due Date', hidden: true },
      { key: 'fund', display: 'Fund' },
      { key: 'fundManager', display: 'Fund Manager' },
      { key: 'gpName', display: 'Fund Manager', hidden: true },
      { key: 'companyName', display: 'Company Name', hidden: true },
      { key: 'supplierName', display: 'Supplier', hidden: true },
      { key: 'transactionId', display: 'Transaction', hidden: true },
      { key: 'orderId', display: 'Order', hidden: true },
      { key: 'limitedPartner', display: 'LP', hidden: true },
      { key: 'placementAgent', display: 'Agent', hidden: true },
      { key: 'bankName', display: 'Custodian', hidden: true },
      { key: 'accountNumber', display: 'Account number', hidden: true },
      { key: 'uploadDate', display: 'Upload Date' },
      { key: 'uploadBy', display: 'Uploaded By' },
      { key: 'verifyStatus', display: 'Verify Status' },
      { key: 'verifiedBy', display: 'Verified By' },
    ],
    am_performance: [
      { key: 'docName', display: 'PDF Name' },
      { key: 'documentType', display: 'Document Type', hidden: true },
      { key: 'dueDate', display: 'Reporting Date' },
      { key: 'paymentDueDate', display: 'Due Date', hidden: true },
      { key: 'fund', display: 'Fund' },
      { key: 'fundManager', display: 'Fund Manager' },
      { key: 'gpName', display: 'Fund Manager', hidden: true },
      { key: 'companyName', display: 'Company Name', hidden: true },
      { key: 'supplierName', display: 'Supplier', hidden: true },
      { key: 'transactionId', display: 'Transaction', hidden: true },
      { key: 'orderId', display: 'Order', hidden: true },
      { key: 'limitedPartner', display: 'LP', hidden: true },
      { key: 'placementAgent', display: 'Agent', hidden: true },
      { key: 'bankName', display: 'Custodian', hidden: true },
      { key: 'accountNumber', display: 'Account number', hidden: true },
      { key: 'uploadDate', display: 'Upload Date' },
      { key: 'uploadBy', display: 'Uploaded By' },
      { key: 'verifyStatus', display: 'Verify Status' },
      { key: 'verifiedBy', display: 'Verified By' },
    ],
    am_company_performance: [
      { key: 'docName', display: 'Document Name' },
      { key: 'documentType', display: 'Document Type', hidden: true },
      { key: 'dueDate', display: 'Reporting Date' },
      { key: 'paymentDueDate', display: 'Due Date', hidden: true },
      { key: 'fund', display: 'Fund', hidden: true },
      { key: 'fundManager', display: 'Fund Manager', hidden: true },
      { key: 'gpName', display: 'Fund Manager', hidden: true },
      { key: 'companyName', display: 'Company Name' },
      { key: 'supplierName', display: 'Supplier', hidden: true },
      { key: 'transactionId', display: 'Transaction', hidden: true },
      { key: 'orderId', display: 'Order', hidden: true },
      { key: 'limitedPartner', display: 'LP', hidden: true },
      { key: 'placementAgent', display: 'Agent', hidden: true },
      { key: 'bankName', display: 'Custodian', hidden: true },
      { key: 'accountNumber', display: 'Account number', hidden: true },
      { key: 'uploadDate', display: 'Upload Date' },
      { key: 'uploadBy', display: 'Uploaded By' },
      { key: 'verifyStatus', display: 'Verify Status' },
      { key: 'verifiedBy', display: 'Verified By' },
    ],
    ps_am_capital_account: [
      { key: 'docName', display: 'Document Name' },
      { key: 'documentType', display: 'Document Type', hidden: true },
      { key: 'dueDate', display: 'Reporting Date' },
      { key: 'paymentDueDate', display: 'Due Date', hidden: true },
      { key: 'fund', display: 'Fund' },
      { key: 'fundManager', display: 'Fund Manager', hidden: true },
      { key: 'gpName', display: 'Fund Manager', hidden: true },
      { key: 'companyName', display: 'Company Name', hidden: true },
      { key: 'supplierName', display: 'Supplier', hidden: true },
      { key: 'transactionId', display: 'Transaction' },
      { key: 'orderId', display: 'Order ID', hidden: false },
      { key: 'limitedPartner', display: 'LP', hidden: false },
      { key: 'placementAgent', display: 'Agent', hidden: true },
      { key: 'bankName', display: 'Custodian', hidden: true },
      { key: 'accountNumber', display: 'Account number', hidden: true },
      { key: 'uploadDate', display: 'Upload Date' },
      { key: 'uploadBy', display: 'Uploaded By', hidden: true },
      { key: 'verifyStatus', display: 'Verify Status' },
      { key: 'verifiedBy', display: 'Verified By' },
    ],
    ps_am_proskauer_subscription: [
      { key: 'docName', display: 'Document Name' },
      { key: 'documentType', display: 'Document Type', hidden: true },
      { key: 'dueDate', display: 'Signed' },
      { key: 'paymentDueDate', display: 'Due Date', hidden: true },
      { key: 'fund', display: 'Fund', hidden: false },
      { key: 'fundManager', display: 'Fund Manager', hidden: true },
      { key: 'gpName', display: 'Fund Manager', hidden: true },
      { key: 'companyName', display: 'Company Name', hidden: true },
      { key: 'supplierName', display: 'Supplier', hidden: true },
      { key: 'transactionId', display: 'Transaction', hidden: true },
      { key: 'orderId', display: 'Order ID', hidden: false },
      { key: 'limitedPartner', display: 'LP', hidden: false },
      { key: 'placementAgent', display: 'Agent' },
      { key: 'bankName', display: 'Custodian', hidden: true },
      { key: 'accountNumber', display: 'Account number', hidden: true },
      { key: 'uploadDate', display: 'Upload Date' },
      { key: 'uploadBy', display: 'Uploaded By', hidden: true },
      { key: 'verifyStatus', display: 'Verify Status' },
      { key: 'verifiedBy', display: 'Verified By' },
    ],
    am_contribution_notice: [
      { key: 'docName', display: 'PDF Name' },
      { key: 'documentType', display: 'Document Type', hidden: true },
      { key: 'dueDate', display: 'Due Date', hidden: true },
      { key: 'paymentDueDate', display: 'Due Date' },
      { key: 'fund', display: 'Fund' },
      { key: 'fundManager', display: 'Fund Manager', hidden: true },
      { key: 'gpName', display: 'Fund Manager' },
      { key: 'companyName', display: 'Company Name', hidden: true },
      { key: 'supplierName', display: 'Supplier', hidden: true },
      { key: 'transactionId', display: 'Transaction', hidden: true },
      { key: 'orderId', display: 'Order', hidden: true },
      { key: 'limitedPartner', display: 'LP', hidden: true },
      { key: 'placementAgent', display: 'Agent', hidden: true },
      { key: 'bankName', display: 'Custodian', hidden: true },
      { key: 'accountNumber', display: 'Account number', hidden: true },
      { key: 'uploadDate', display: 'Upload Date' },
      { key: 'uploadBy', display: 'Uploaded By' },
      { key: 'verifyStatus', display: 'Verify Status' },
      { key: 'verifiedBy', display: 'Verified By' },
    ],
    am_distribution_notice: [
      { key: 'docName', display: 'PDF Name' },
      { key: 'documentType', display: 'Document Type', hidden: true },
      { key: 'dueDate', display: 'Issue Date' },
      { key: 'paymentDueDate', display: 'Due Date', hidden: true },
      { key: 'fund', display: 'Fund' },
      { key: 'fundManager', display: 'Fund Manager', hidden: true },
      { key: 'gpName', display: 'Fund Manager' },
      { key: 'companyName', display: 'Company Name', hidden: true },
      { key: 'supplierName', display: 'Supplier', hidden: true },
      { key: 'transactionId', display: 'Transaction', hidden: true },
      { key: 'orderId', display: 'Order', hidden: true },
      { key: 'limitedPartner', display: 'LP', hidden: true },
      { key: 'placementAgent', display: 'Agent', hidden: true },
      { key: 'bankName', display: 'Custodian', hidden: true },
      { key: 'accountNumber', display: 'Account number', hidden: true },
      { key: 'uploadDate', display: 'Upload Date' },
      { key: 'uploadBy', display: 'Uploaded By' },
      { key: 'verifyStatus', display: 'Verify Status' },
      { key: 'verifiedBy', display: 'Verified By' },
    ],
    am_invoice: [
      { key: 'docName', display: 'PDF Name' },
      { key: 'documentType', display: 'Document Type', hidden: true },
      { key: 'dueDate', display: 'Issued' },
      { key: 'paymentDueDate', display: 'Due Date', hidden: true },
      { key: 'fund', display: 'Fund' },
      { key: 'fundManager', display: 'Fund Manager', hidden: true },
      { key: 'gpName', display: 'Fund Manager', hidden: true },
      { key: 'companyName', display: 'Company Name', hidden: true },
      { key: 'supplierName', display: 'Supplier' },
      { key: 'transactionId', display: 'Transaction', hidden: true },
      { key: 'orderId', display: 'Order', hidden: true },
      { key: 'limitedPartner', display: 'LP', hidden: true },
      { key: 'placementAgent', display: 'Agent', hidden: true },
      { key: 'bankName', display: 'Custodian', hidden: true },
      { key: 'accountNumber', display: 'Account number', hidden: true },
      { key: 'uploadDate', display: 'Upload Date' },
      { key: 'uploadBy', display: 'Uploaded By' },
      { key: 'verifyStatus', display: 'Verify Status' },
      { key: 'verifiedBy', display: 'Verified By' },
    ],
    am_reference_custodian_bank_statement: [
      { key: 'docName', display: 'PDF Name' },
      { key: 'documentType', display: 'Document Type', hidden: true },
      { key: 'dueDate', display: 'Performance Date' },
      { key: 'paymentDueDate', display: 'Due Date', hidden: true },
      { key: 'fund', display: 'Fund', hidden: true },
      { key: 'fundManager', display: 'Fund Manager', hidden: true },
      { key: 'gpName', display: 'Fund Manager', hidden: true },
      { key: 'companyName', display: 'Company Name', hidden: true },
      { key: 'supplierName', display: 'Supplier', hidden: true },
      { key: 'transactionId', display: 'Transaction', hidden: true },
      { key: 'orderId', display: 'Order', hidden: true },
      { key: 'limitedPartner', display: 'LP', hidden: true },
      { key: 'placementAgent', display: 'Agent', hidden: true },
      { key: 'bankName', display: 'Custodian' },
      { key: 'accountNumber', display: 'Account number' },
      { key: 'uploadDate', display: 'Upload Date' },
      { key: 'uploadBy', display: 'Uploaded By' },
      { key: 'verifyStatus', display: 'Verify Status' },
      { key: 'verifiedBy', display: 'Verified By' },
    ],
  };
};

const initialState = () => ({
  all: [],
  sorted: [],
  selectedDocumentId: null,
  tableHeaders: tableHeaders(),
});

const store = {
  namespaced: true,
  state: {
    ...storeState,
    all: [],
    sorted: [],
    selectedDocumentId: null,
    tableHeaders: tableHeaders(),
  },

  getters: {
    ...storeStateGetters,
    all: (state) => state.all,
    documentById: (state) => (documentId) => state.all.find((doc) => doc.id === documentId),
    sorted: (state) => state.sorted,
    tableHeaders: (state) => (documentType) => (documentType && (documentType in state.tableHeaders)
      ? state.tableHeaders[documentType]
      : state.tableHeaders.DEFAULT),
  },

  mutations: {
    ...storeStateMutations,
    RESET(state) {
      Object.assign(state, initialState());
    },
    SET_ALL(state, all) {
      state.all = all;
    },
    SET_SORTED(state, sorted) {
      state.sorted = sorted;
    },
    TOGGLE_SELECTED_ON_SORTED(state, documentId) {
      logger.debug('Toggling:', documentId);
      const found = state.sorted.find((s) => s.id === documentId);
      found.selected = !found.selected;
      logger.debug('  toggled:', found.selected);
    },
  },

  actions: {
    ...storeStateActions,
    getDocuments: async ({ rootGetters }, stage) => {
      logger.debug('  ** Getting Documents **  offline:', rootGetters['documentRequest/offline']);

      if (!rootGetters['documentRequest/offline']) {
        logger.debug('env:', process.env);
        let url = 'documentrequest';
        if (stage) {
          url = url.concat(`?stage=${stage}`);
        }
        return (new Api(process.env, rootGetters['authenticate/idToken'])).get(url);
      }
      return new Promise(((resolve) => {
        setTimeout(resolve, 500, GETDocumentRequest());
      }));
    },
    getDocumentLinks: async ({ rootGetters }, { docId, fileName }) => {
      logger.debug('  ** Getting Document Links **  offline:', rootGetters['documentRequest/offline']);
      // If offline, retrieve local document download links (pdf and annotations)
      if (rootGetters['documentRequest/offline']) {
        return new Promise(((resolve) => {
          setTimeout(resolve, 500, GETDocumentLinks());
        }));
      }

      if (docId === null || docId === undefined) {
        throw Error('PDF does not exist');
      }
      logger.debug('env:', process.env);
      const path = `documentrequest/${docId}/document${fileName && `?filename=${fileName}`}`;
      logger.info('Getting pdf: ', path);
      return (new Api(process.env, rootGetters['authenticate/idToken'])).get(path);
    },
    init: async ({ dispatch, commit, rootGetters }, stage) => {
      try {
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        commit('RESET');
        const docs = await dispatch('getDocuments', stage);
        // validateDocuments(docs);
        const dateFormat = rootGetters['localisation/dateFormat'];
        const documentTypes = rootGetters['documentTypes/documentTypesToDisplayMapping'];
        logger.debug('Date format:', dateFormat.format);
        const transformed = backendToDocuments(clonedeep(docs), dateFormat, documentTypes);
        logger.debug('Fetched & transformed documents:', transformed);
        commit('SET_ALL', transformed);
        commit('SET_SORTED', clonedeep(transformed));
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    deleteDocumentRequests: ({ rootGetters }, ids) => {
      logger.debug('  ** Deleting documents **', ids, 'offline:', rootGetters['documentRequest/offline']);
      if (!rootGetters['documentRequest/offline']) {
        const body = { uuids: ids };
        return (new Api(process.env, rootGetters['authenticate/idToken']))
          .post('documentrequest/delete', body);
      }

      return new Promise(((_, reject) => setTimeout(reject, 500, DELETEDocumentRequest())));
    },
    requeueDocuments: ({ rootGetters }, docs) => {
      logger.debug('Requeue documents', docs);
      const body = { document_requests: docs };
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('documentrequest/requeue', body);
    },
    selectOnSorted: ({ commit, rootGetters }, isSelected) => {
      logger.debug('Selecting all documents');
      const selectedSorted = rootGetters['documents/sorted'].map((doc) => ({
        ...doc,
        selected: isSelected,
      }));
      commit('SET_SORTED', selectedSorted);
    },

    sortBy: ({ getters, commit, rootGetters }, { columnKey, columnIndex, direction }) => {
      // direction:: 0:None, 1:ASC, 2:DESC
      if (direction === 0) {
        commit('SET_SORTED', clonedeep(getters.all));
        return;
      }
      logger.debug('sorting', getters);
      logger.debug('sorting...', columnIndex, columnKey, direction);
      const allCopy = clonedeep(getters.all);
      if (['dueDate', 'uploadDate'].includes(columnKey)) {
        logger.info('Sorting by date');
        dateSort(allCopy, columnIndex, direction, rootGetters['localisation/dateFormat'].format);
      } else {
        allCopy.sort((row1, row2) => {
          const r1 = row1.fields[columnIndex].text;
          const r2 = row2.fields[columnIndex].text;
          if (direction === 1) {
            if (r1 < r2) {
              return -1;
            }
            return (r1 > r2) ? 1 : 0;
          }

          if (r1 < r2) {
            return 1;
          }
          return (r1 > r2) ? -1 : 0;
        });
      }
      logger.debug('to sort:', allCopy);

      commit('SET_SORTED', allCopy);
    },
    toggleSelectedOnRow({ commit }, documentId) {
      commit('TOGGLE_SELECTED_ON_SORTED', documentId);
    },
    changeDocumentRequestsState: ({ rootGetters }, { ids, stage }) => {
      const body = { ids, stage };
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('documentrequest/state', body);
    },
    /**
     * Remove document requests from store by their ids.
     */
    removeDocumentRequests: ({ rootGetters, commit }, ids) => {
      const updatedAll = rootGetters['documents/all'].filter((dr) => !ids.includes(dr.document_request_id));
      commit('SET_ALL', updatedAll);
      const updatedSorted = rootGetters['documents/sorted'].filter((dr) => !ids.includes(dr.document_request_id));
      commit('SET_SORTED', updatedSorted);
    },
  },
};

export default store;
