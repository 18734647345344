<template>
  <div
    class="explorer"
  >
    <div class="explorer-content-main">
      <PaginationButtons
        :go-to-prev-page="goToPrevPage"
        :go-to-next-page="goToNextPage"
        :page-number="relatedEntitiesPageIndex + 1"
        :disable-prev="relatedEntitiesPageIndex === 0 || loading"
        :disable-next="isLastPage || loading"
      />
      <loading-overlay
        v-show="entityRelationshipsStoreIsLoading"
        :overlay-opaque="true"
        :style="{'background': 'transparent'}"
      />
      <entity-relationships-table
        v-if="relatedEntitiesPage?.entities"
        :related-entities="relatedEntitiesPage?.entities ?? []"
        :row-selection-data="entitySelection"
        @updateRowSelection="onUpdateEntitySelection"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { allowedStates } from '@/store/helpers/storeState';
import LoadingOverlay from '@/components/general/LoadingOverlay.vue';
import EntityRelationshipsTable from '@/components/entities/entityRelationships/EntityRelationshipsTable.vue';
import PaginationButtons from '../PaginationButtons.vue';

export default {
  components: {
    EntityRelationshipsTable,
    PaginationButtons,
    LoadingOverlay,
  },
  props: {
    entitySelection: {
      type: Object,
      required: true,
    },
  },
  emits: ['updateRelatedEntitySelection'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      relatedEntitiesPage: 'entityRelationships/relatedEntitiesPage',
      relatedEntitiesPageIndex: 'entityRelationships/relatedEntitiesPageIndex',
      isLastPage: 'entityRelationships/isCurrentPageLastPage',
      storeStatus: 'entityRelationships/storeStatus',
    }),
    entityRelationshipsStoreIsLoading() {
      return this.storeStatus === allowedStates.IS_LOADING;
    },
    totalEntitiesOnCurrentPage() {
      return this.relatedEntitiesPage?.entityCount;
    },
  },
  watch: {
    totalEntitiesOnCurrentPage(newVal) {
      if (newVal === 0 && this.relatedEntitiesPageIndex !== 0) {
        this.goToPrevPage();
      }
    },
  },
  methods: {
    ...mapActions({
      nextPage: 'entityRelationships/nextPage',
      prevPage: 'entityRelationships/prevPage',
    }),
    onUpdateEntitySelection(selection) {
      this.$emit('updateRelatedEntitySelection', selection);
    },
    async goToNextPage() {
      this.loading = true;
      if (!this.isLastPage) {
        await this.nextPage();
      }
      this.loading = false;
    },
    async goToPrevPage() {
      this.loading = true;
      if (!(this.relatedEntitiesPageIndex <= 0)) {
        await this.prevPage();
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.explorer {
  position: relative;
}
</style>
