import clonedeep from 'lodash.clonedeep';
import isSet from '@/store/helpers/isSet';
import getEntityNameMetrics from '@/store/helpers/metrics/getEntityNameMetrics';
import { storeStateMutations } from '@/store/helpers/storeState';
import { logger } from '@/store/logger';

const store = {
  namespaced: true,
  state: {
    entityNameMetrics: [],
  },

  getters: {
    entityNameMetrics: (state) => state.entityNameMetrics,
    /* Format entity name metrics for option select in the store so that we don't have to do it for each EntityLink component */
    entityNameMetricsForOptionSelect: (state) => (groupIds) => state.entityNameMetrics
      .filter((g) => groupIds.includes(g.groupIdentifier))
      .map((g) => ({
        groupDisplay: g.groupDisplay,
        entityMetrics: g.metrics.map((metric) => ({ id: metric.id, name: metric.datapoints[0].value, gi: g.groupIdentifier })),
      })),
  },

  mutations: {
    ...storeStateMutations,
    SET_ENTITY_NAME_METRICS_INDEX(state, { index, metrics }) {
      state.entityNameMetrics[index].metrics = metrics;
    },
  },

  actions: {
    init: ({ commit }, initialEntityNameMetrics) => {
      logger.debug('Initialising entityNameMetrics:', initialEntityNameMetrics);
      commit('SET_PROP', { key: 'entityNameMetrics', value: initialEntityNameMetrics });
    },
    updateForTable: ({ commit, getters, rootGetters }, { tableIdx, rows, metrics }) => {
      logger.debug('updateForTable entityNameMetrics for table:', tableIdx);
      logger.debug('updateForTable grouping:', rootGetters['verifyDocument/document']?.grouping);
      const groupId = rootGetters['verifyDocument/document']?.grouping.groups.find((g) => g.tableIndex === tableIdx).groupIdentifier;
      if (!isSet(groupId)) {
        return;
      }

      getters.entityNameMetrics.forEach((g, index) => {
        if (g.groupIdentifier === groupId) {
          const updatedMetrics = clonedeep(getEntityNameMetrics(rows, metrics));
          commit('SET_ENTITY_NAME_METRICS_INDEX', { index, metrics: updatedMetrics });
        }
      });
    },
  },
};

export default store;
