import { backendToMetric, metricToBackend } from './transformMetric';

const backendToGrouping = (backendGrouping) => ({
  id: backendGrouping.id,
  groupDisplay: backendGrouping.group_display,
  groups: backendGrouping.groups.map((g) => backendToGrouping(g)),
  metrics: backendGrouping.metrics,
  allowRepeatable: backendGrouping.allow_repeatable,
  // groupIdentifier: backendGrouping.groupIdentifier,
});

const backendToDocumentRequest = (docReq) => ({
  id: String(docReq.DOCUMENT_REQUEST.id),
  verifyStatus: docReq.DOCUMENT_REQUEST.verified === true ? 'DOC_VERIFIED' : 'DOC_UNVERIFIED',
  documentType: docReq.DOCUMENT_TYPE.name,
  metrics: docReq.METRICS_AND_DATAPOINTS.map((m, i) => backendToMetric(m, i)),
  grouping: backendToGrouping(docReq.GROUPING),
});

const documentRequestToBackend = (docReq) => ({
  documentRequestId: docReq.id,
  verified: docReq.verifyStatus === 'DOC_VERIFIED',
  documentType: docReq.documentType,
  metrics: docReq.metrics.map((m, i) => metricToBackend(m, i)),
});

export { backendToDocumentRequest, documentRequestToBackend };
