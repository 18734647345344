const WARNINGS = {
  EXPORT_ALL_METRICS: 'The data you are attempting to export includes unverified information which may contain errors. Do you wish to proceed?',
};
export default {
  EXPORT_VERIFIED_METRICS: 'EXPORT_VERIFIED_METRICS',
  FORMAT_OPTIONS: [
    { id: 'EXPORT_AS_CSV', label: 'CSV' },
    { id: 'EXPORT_AS_EXCEL', label: 'EXCEL' },
    { id: 'EXPORT_AS_JSON', label: 'JSON' },
  ],
  MAIN_EXPORT_OPTIONS: [
    { id: 'EXPORT_VERIFIED_METRICS', label: 'Export Verified Metrics' },
    { id: 'EXPORT_ALL_METRICS', label: 'Export All Metrics' },
  ],
  DEFAULT_MAIN_EXPORT_OPTION: 'EXPORT_VERIFIED_METRICS',
  WARNINGS,
  FORMAT_MAPPINGS: {
    EXPORT_AS_CSV: 'csv',
    EXPORT_AS_EXCEL: 'xlsx',
    EXPORT_AS_JSON: 'json',
    EXPORT_AS_DOCUMENT_GRAPH: 'document_graph',
  },
  // TODO: Remove once export flow is removed from verification screen
  EXPORT_AS_CSV: 'EXPORT_AS_CSV',
  EXPORT_AS_EXCEL: 'EXPORT_AS_EXCEL',
  EXPORT_AS_DOCUMENT_GRAPH: 'EXPORT_AS_DOCUMENT_GRAPH',
};
