import Api from '@/store/helpers/api';
import { logger } from '@/store/logger';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from './helpers/storeState';

const store = {
  namespaced: true,
  state: {
    ...storeState,
    entities: [],
    entitiesNextPageToken: null,
  },

  getters: {
    ...storeStateGetters,
    entities: (state) => state.entities,
    entitiesNextPageToken: (state) => state.entitiesNextPageToken,
  },

  mutations: {
    ...storeStateMutations,
    SET_ENTITIES(state, entities) {
      state.entities = entities;
    },
    SET_ENTITIES_NEXT_PAGE_TOKEN(state, entitiesNextPageToken) {
      state.entitiesNextPageToken = entitiesNextPageToken;
    },
    APPEND_ENTITIES(state, entities) {
      state.entities = state.entities.concat(entities);
    },
    DELETE_ENTITY(state, entityId) {
      const index = state.entities.findIndex((entity) => entity.interDocumentEntityId === entityId);
      state.entities.splice(index, 1);
    },
    UPDATE_ENTITY(state, { entityId, akas, name }) {
      const entityToUpdate = state.entities.find((entity) => entity.interDocumentEntityId === entityId);
      if (entityToUpdate) {
        entityToUpdate.akas = akas;
        entityToUpdate.name = name;
      }
    },
  },

  actions: {
    ...storeStateActions,
    init: async ({ dispatch, commit }) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      try {
        logger.debug('Initializing entity store');
        const response = await dispatch('getEntities', { name: null, fin: null, nextPageToken: null });
        logger.debug('Got entities: ', response);
        commit('SET_ENTITIES', response.entities);
        commit('SET_ENTITIES_NEXT_PAGE_TOKEN', response.nextPageToken);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    getEntities: async ({ rootGetters }, {
      name, fin, uuids = [], nextPageToken,
    }) => {
      logger.debug('Getting entities');

      // Build filters omitting nullable fields.
      const filters = {};
      if (fin !== null) {
        filters.fin = fin;
      }
      if (name !== null) {
        filters.name = name;
      }
      if (uuids.length > 0) {
        filters.uuids = uuids;
      }

      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('interdocumententity', { page_token: nextPageToken, filters });
    },
    /**
     * Place request for the next batch of entities if there is a next page token.
     * It is important to persist any filters if there are any.
     */
    loadMoreEntities: async ({ getters, dispatch, commit }, { name, fin }) => {
      logger.debug('Loading more entities');
      const nextPageToken = getters.entitiesNextPageToken;
      if (!nextPageToken) {
        return;
      }
      const response = await dispatch('getEntities', { name, fin, nextPageToken });
      commit('APPEND_ENTITIES', response.entities);
      commit('SET_ENTITIES_NEXT_PAGE_TOKEN', response.nextPageToken);
    },
    /**
     * Update entities by applying a new search query.
     */
    searchEntities: async ({ dispatch, commit }, { name, fin, uuids = [] }) => {
      logger.debug('Getting entities by name', name, ' and fin', fin);
      const response = await dispatch('getEntities', {
        name, fin, uuids, nextPageToken: null,
      });
      commit('SET_ENTITIES', response.entities);
      commit('SET_ENTITIES_NEXT_PAGE_TOKEN', response.nextPageToken);
      return response;
    },
    /**
     * Delete entity given its' UUID.
     * Remove it from the store after request success.
     */
    deleteEntity: async ({ rootGetters, commit }, id) => {
      logger.debug('Deleting entity: ', id);
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .post('interdocumententity/delete', { uuids: [id] })
        .then(() => {
          logger.debug('Entity deleted successfully');
          commit('DELETE_ENTITY', id);
        })
        .catch((e) => {
          logger.error('Failed to delete entity: ', id);
          throw e;
        });
    },
    /**
     * Update a given entity.
     */
    updateEntity: async ({ rootGetters, commit }, { id, akas, name }) => {
      logger.debug('Updating entity: ', id, 'with akas:', akas, ' and name:', name);
      try {
        const response = await new Api(process.env, rootGetters['authenticate/idToken']).patch(`interdocumententity/${id}`, { akas, name });
        logger.debug('Entity updated successfully');
        commit('UPDATE_ENTITY', { entityId: id, akas, name });
        return response;
      } catch (e) {
        logger.error('Failed to update entity: ', id);
        throw e;
      }
    },
  },
};

export default store;
