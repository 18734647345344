function isEnabled(flag: string): boolean {
  try {
    return process.env[flag].toLowerCase() === 'true';
  } catch {
    return false;
  }
}
const FEATURE_FLAGS = {
  METRICS_INFINITE_SCROLL: isEnabled('VUE_APP_METRICS_INFINITE_SCROLL'),
  MULTI_LINGUAL_SUPPORT: isEnabled('VUE_APP_MULTI_LINGUAL_SUPPORT'),
  USER_SETTINGS_UPLIFT: isEnabled('VUE_APP_USER_SETTINGS_UPLIFT'),
};

export default FEATURE_FLAGS;
