/**
 * --- Information ---
 *
 * The backend sends us a permission level for the logged in user.
 * Given this permission level, we can work out whether or not a user
 * has a permission using bitwise addition.
 *
 * --- Usage ---
 *
 * We need to make sure that we have cached the user settings. The
 * 'checkPermission' function in the store 'localisation.js' does it
 * automatically.
 *
 * Use in vue component following this example:
 *     data() {
 *       return {
 *         canSeeUsersSettings: false,
 *       };
 *     ...
 *     async created() {
 *       this.canSeeUsersSettings = await (this.checkPermission('CAN_ADD_USERS'));
 *     },
 *     ...
 *     methods: {
 *       ...mapActions({
 *         checkPermission: 'localisation/checkPermission',
 *       }),
 */

const permissionGroups = {
  Users: [
    {
      display: 'List / View',
      permission: 'CAN_LIST_USERS',
      level: 4,
    },
    {
      display: 'Create',
      permission: 'CAN_ADD_USERS',
      level: 1,
    },
    {
      display: 'Update',
      permission: 'CAN_UPDATE_USERS',
      level: 8,
    },
    {
      display: 'Activate / Deactivate',
      permission: 'CAN_UPDATE_USER_ACCOUNT_STATUS',
      level: 16,
    },
  ],
  Documents: [
    {
      display: 'Verify',
      permission: 'CAN_VERIFY_DOCUMENTS',
      level: 32,
    },
  ],
  Entities: [
    {
      display: 'Create & Update',
      permission: 'CAN_CREATE_UPDATE_ENTITIES',
      level: 2,
    },
    {
      display: 'Delete',
      permission: 'CAN_DELETE_ENTITIES',
      level: 64,
    },
  ],
};

const accountStatus = {
  1: 'Creating',
  2: 'Active',
  3: 'Deactivated',
};

/**
 * Returns dict of { permissionName: permissionLevel }.
 */
const getPermissionsFromGroups = (groups) => {
  const result = {};
  Object.values(groups).forEach(
    (group) => {
      group.forEach((item) => {
        const { permission } = item;
        result[permission] = item.level;
      });
    },
  );
  return result;
};

// eslint-disable-next-line no-bitwise
const bitwisePermissionCheck = (permission, userPermissionLevel) => (userPermissionLevel & permission) === permission;

/**
 * Check if a user has a certain permission.
 * @param {String} permission permission to check.
 * @param {Integer} userPermissionLevel current permission level of the user.
 * @returns {Boolean} true if user has the given permission
 */
const userHasPermission = (permission, userPermissionLevel) => {
  const permissions = getPermissionsFromGroups(permissionGroups);
  return bitwisePermissionCheck(permissions[permission], userPermissionLevel);
};

const getAccountStatusDisplay = (status) => accountStatus[status];

export {
  permissionGroups,
  userHasPermission,
  bitwisePermissionCheck,
  getAccountStatusDisplay,
  getPermissionsFromGroups,
};
