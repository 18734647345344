<template>
  <button
    :class="buttonClasses"
    :disabled="isDisabled"
    @click="onClick"
  >
    <span class="btn-content"><slot />{{ message }}
      <alert-circle-icon
        v-if="errorMessage"
        v-tooltip="errorMessage"
        stroke="red"
      />
      <lock-icon
        v-if="insufficientPermissions"
        v-tooltip="'Insufficient Permissions'"
        siz="1.5x"
        class="lock-icon"
      />
    </span>
    <span
      class="btn-loader"
    >
      <span class="btn-loader-internal" />
    </span>
  </button>
</template>
<script>
import { AlertCircleIcon, LockIcon } from '@zhuowenli/vue-feather-icons';
import buttonDisplays from './buttonDisplays';

export default {
  components: { AlertCircleIcon, LockIcon },
  props: {
    buttonDisplay: {
      type: String,
      default: 'BTN_PRIMARY',
    },
    customClasses: {
      type: Object,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    insufficientPermissions: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: '',
    },
  },
  emits: ['onClick'],
  data() {
    return {
      buttonDisplays,
    };
  },
  computed: {
    buttonClass() {
      return buttonDisplays[this.buttonDisplay].buttonClass;
    },
    buttonClasses() {
      return {
        ...this.customClasses,
        [this.buttonClass]: true,
        'action-button': true,
        'btn-loading': this.isLoading,
        'btn-disabled': this.isDisabled,
      };
    },
  },
  methods: {
    onClick(e) {
      if (!this.isLoading) {
        this.$emit('onClick', e);
      }
    },
  },
};
</script>
