/* eslint-disable global-require, import/no-unresolved */
import { logger } from '@/store/logger';

export default () => {
  try {
    let annotations = {};
    let annotationsEn = {};
    annotationsEn = require('../../../../public/local_documents/performance_annotations_en.json');
    annotations = require('../../../../public/local_documents/performance_annotations.json');
    const pdf = require('../../../../public/local_documents/performance.pdf');
    logger.info('local annotations:', annotations, ' local pdf:', pdf);
    return { pdf, annotations, annotations_en: annotationsEn };
  } catch (e) {
    logger.warn('No local annotations or pdf found', e);
    return {};
  }
};
