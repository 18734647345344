<template>
  <div class="multi-tag-input">
    <div
      v-for="(tag, idx) in modelValue"
      :key="idx"
      :class="{ 'input-tag': true, 'input-tag-disabled': isReadOnlyValue(tag) }"
    >
      <div :class="{ 'input-tag-content': !isReadOnlyValue(tag) }">
        <input
          :value="tag"
          :disabled="isReadOnlyValue(tag)"
          @blur="onInputBlur"
          @input="updateTagText(idx, $event.target.value)"
        >
      </div>
      <div
        v-if="!isReadOnlyValue(tag)"
        class="input-tag-delete"
        @click.stop="deleteInputTag(idx)"
      >
        <x-icon />
      </div>
    </div>
    <div
      class="input-tag input-tag-add"
      @click.stop="addInputTag"
    >
      <div class="add-icon">
        <plus-icon />
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from 'vue-toastification';
import { PlusIcon, XIcon } from '@zhuowenli/vue-feather-icons';
import { errorMessages } from '@/store/helpers/display/toastMessages';

export default {
  components: {
    PlusIcon,
    XIcon,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    readOnlyValues: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue', 'inputBlur'],
  data() {
    return {
      toast: useToast(),
    };
  },
  methods: {
    addInputTag() {
      const arrCopy = Array.from(this.modelValue);
      arrCopy.push('');
      this.$emit('update:modelValue', arrCopy);
    },
    deleteInputTag(idx) {
      const arrCopy = Array.from(this.modelValue);
      arrCopy.splice(idx, 1);
      this.$log.info('deleting idx', idx, 'MultiTagInput:', arrCopy);
      this.$emit('update:modelValue', arrCopy);
      this.onInputBlur();
    },
    updateTagText(idx, value) {
      // This is to handle an edge case when the user tries to add/update
      // an aka with the same value as a read only aka. In this case, we
      // remove the aka and toast an error.
      if (this.isReadOnlyValue(value)) {
        this.deleteInputTag(idx);
        this.toast.error(errorMessages.CANNOT_DUPLICATE_AKA);
        return;
      }

      const arrCopy = Array.from(this.modelValue);
      arrCopy[idx] = value;
      this.$emit('update:modelValue', arrCopy);
    },
    onInputBlur() {
      this.$emit('inputBlur');
    },
    isReadOnlyValue(value) {
      return this.readOnlyValues.includes(value);
    },
  },
};
</script>
