<template>
  <aside>
    <p class="details-small-header">
      <b>Email:</b> {{ user.email }}
    </p>
    <p class="details-small-header">
      <b>Account status:</b> {{ getAccountStatusDisplay(user.account_status) }}
    </p>
    <p class="details-header">
      Platform Permissions:
    </p>
    <ul class="permission-groups-container">
      <li
        v-for="(group, groupName) in permissionGroups"
        :key="groupName"
      >
        <p class="permission-groups-header">
          {{ groupName }}
        </p>
        <ul class="permission-list-container">
          <li
            v-for="permission in group"
            :key="permission.permission"
            class="permissions-container"
          >
            <checkbox-input
              :id="`permission-${permission.permission}`"
              :is-disabled="!canUpdateUser"
              :model-value="bitwisePermissionCheck(permission.level, updatedUser.permissions)"
              @update:modelValue="onUpdatePermission(permission.level)"
            />
            <p> {{ permission.display }} </p>
          </li>
        </ul>
      </li>
    </ul>
    <div class="details-button-container">
      <action-button
        v-if="canUpdateUser"
        message="Update"
        :is-disabled="!userUpdate"
        @onClick="updateUser"
      />
      <action-button
        v-if="canDeactivateUser"
        button-display="BTN_SECONDARY"
        :message="deactivateOption ? 'Deactivate' : 'Activate'"
        @onClick="deactivateUser"
      />
    </div>
  </aside>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import {
  permissionGroups, bitwisePermissionCheck, getAccountStatusDisplay,
} from '@/store/helpers/mapping/permissions';
import CheckboxInput from '@/components/forms/CheckboxInput.vue';
import clonedeep from 'lodash.clonedeep';
import ActionButton from '@/components/general/buttons/ActionButton.vue';

export default {
  components: {
    CheckboxInput,
    ActionButton,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'updateUser',
    'deactivateUser',
  ],
  data() {
    return {
      permissionGroups,
      updatedUser: clonedeep(this.user),
      canUpdateUser: false,
      canDeactivateUser: false,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'localisation/user',
    }),
    /**
     * True if there was an update made to the user.
     */
    userUpdate() {
      return this.user.permissions !== this.updatedUser.permissions;
    },
    /**
     * Indicates if the user can be deactivated or activated.
     */
    deactivateOption() {
      return this.user.account_status === 2;
    },
  },
  watch: {
    // Must recalculate permissions when another user is clicked.
    user: {
      handler(val) {
        this.updatedUser = clonedeep(val);
        this.userCanBeUpdated()
          .then((canUpdateUser) => {
            this.canUpdateUser = canUpdateUser && this.deactivateOption;
            this.userCanBeDeactivated(canUpdateUser)
              .then((canDeactivateUser) => {
                this.canDeactivateUser = canDeactivateUser;
              });
          });
      },
      deep: true,
    },
  },
  async created() {
    /**
     * Permission constraints:
     * - A user must have permission 'CAN_UPDATE_USERS' to update users.
     * - A user must have both permissions 'CAN_UPDATE_USERS' and 'CAN_UPDATE_USER_ACCOUNT_STATUS'
     *   to activate/deactivate another user.
     * - A user cannot update or activate/deactivate themselves.
     * - A user cannot be updated while it is deactivated.
     *
     * Note: In code, 'deactivate' can also be interpreted as 'activate' (they are opposites).
     */
    const softUserUpdate = await this.userCanBeUpdated();
    this.canUpdateUser = softUserUpdate && this.deactivateOption;
    this.canDeactivateUser = await this.userCanBeDeactivated(softUserUpdate);
  },
  methods: {
    ...mapActions({
      checkPermission: 'localisation/checkPermission',
    }),
    getAccountStatusDisplay,
    bitwisePermissionCheck,
    /**
     * Updating permission increases/decreases the permission level
     * correspondingly.
     */
    onUpdatePermission(permission) {
      if (bitwisePermissionCheck(permission, this.updatedUser.permissions)) {
        this.updatedUser.permissions -= permission;
      } else {
        this.updatedUser.permissions += permission;
      }
      this.$log.debug('User permission updated by:', permission, '; new level:', this.updatedUser.permissions);
    },
    updateUser() {
      this.$emit('updateUser', this.updatedUser);
    },
    isSelfUser(user) {
      return this.loggedInUser.id === user.id;
    },
    deactivateUser() {
      this.$emit('deactivateUser');
    },
    async userCanBeUpdated() {
      return await (this.checkPermission('CAN_UPDATE_USERS')) && !this.isSelfUser(this.user);
    },
    async userCanBeDeactivated(userCanBeUpdated) {
      return await (this.checkPermission('CAN_UPDATE_USER_ACCOUNT_STATUS')) && userCanBeUpdated;
    },
  },
};
</script>
