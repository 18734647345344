import { permissionGroups, userHasPermission } from './permissions';

type Role = {
  display: string,
  color: string,
};

const ROLES: { [key: string]: Role } = {
  ADMIN: {
    display: 'Admin',
    color: '#D3D2FF',
  },
  MEMBER: {
    display: 'Member',
    color: '#C2E9FA',
  },
};

function userRole(userPermissionLevel: number): Role {
  const userManagementPermissions = permissionGroups.Users;
  const hasUserManagementPermission = userManagementPermissions.some(
    (permission) => userHasPermission(permission.permission, userPermissionLevel),
  );
  return hasUserManagementPermission ? ROLES.ADMIN : ROLES.MEMBER;
}

export {
  ROLES,
  Role,
  userRole,
};
