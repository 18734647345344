<template>
  <div
    id="app"
    class="app"
  >
    <Nav
      v-cloak
      v-if="showHozNav"
    />
    <div :class="{'main': true, 'main-fullscreen': !showHozNav, 'main-fullscreen-top-nav': pageHasTopNav}">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Nav from './components/nav/Nav.vue';

export default {
  name: 'App',
  components: {
    Nav,
  },
  computed: {
    showHozNav() {
      if (!this.$route.name) {
        return false;
      }

      return !this.$route.matched.some((record) => record.meta && record.meta.shouldHideNav);
    },
    pageHasTopNav() {
      // This property is a bit of a hack. It disables the screen height from being
      // 100%, ensuring that the top nav does not add additional vertical page height
      if (!this.$route.name) {
        return false;
      }

      return this.$route.matched.some((record) => record.meta && record.meta.hasTopNav);
    },
  },
  methods: {
    ...mapActions({
      logout: 'authenticate/logout',
    }),
    signOut() {
      this.logout();
      this.$router.push({ name: 'SignIn' });
    },
  },
};
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

    [v-cloak] {
        display: none;
    }

    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active {
        transition: opacity 0s ease;
    }

    .fade-leave {

    }

    .fade-leave-active {
        transition: opacity 0.0s ease;
        opacity: 0;
    }
</style>
