<template>
  <div class="nav">
    <div class="logo">
      <router-link to="verification">
        <slot>
          <img
            width="128"
            alt="Freyda Icon"
            src="../../assets/freyda_logo.svg"
          >
        </slot>
      </router-link>
    </div>

    <ul>
      <router-link
        v-slot="{ href, navigate, isActive, isExactActive }"
        to="/upload"
        custom
      >
        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active', 'nav-li']">
          <upload-icon
            size="1.25x"
            class="nav-icon"
            :stroke="isExactActive ? 'black' : 'white'"
          />
          <a
            :href="href"
            class="nav-link"
            @click="navigate"
          >Upload</a>
        </li>
      </router-link>
      <router-link
        v-slot="{ href, navigate, isActive, isExactActive }"
        to="/usage"
        custom
      >
        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active', 'nav-li']">
          <pie-chart-icon
            size="1.25x"
            class="nav-icon"
            :stroke="isExactActive ? 'black' : 'white'"
          />
          <a
            :href="href"
            class="nav-link"
            @click="navigate"
          >Usage</a>
        </li>
      </router-link>
      <div
        @mouseover="verificationHoverOver"
        @mouseleave="verificationHoverLeave"
      >
        <router-link
          v-slot="{ href, navigate, isActive, isExactActive }"
          to="/verification"
          custom
        >
          <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
            <folder-icon
              size="1.25x"
              class="nav-icon"
              :stroke="isExactActive ? 'black' : 'white'"
            />
            <a
              :href="href"
              @click="navigate"
            >Verification</a>
            <badge :value="unverifiedDocumentCount" />
          </li>
        </router-link>
        <div
          v-if="verificationHover"
          class="dropdown-menu-animation"
        >
          <router-link
            v-slot="{ href, navigate, isActive, isExactActive }"
            to="/verification"
            custom
          >
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              <a
                :href="href"
                class="verification-dropdown"
                @click="navigate"
              >Verification</a>
            </li>
          </router-link>
          <router-link
            v-slot="{ href, navigate, isActive, isExactActive }"
            to="/archive"
            custom
          >
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              <a
                :href="href"
                class="verification-dropdown"
                @click="navigate"
              >Archive</a>
            </li>
          </router-link>
          <router-link
            v-slot="{ href, navigate, isActive, isExactActive }"
            to="/trash"
            custom
          >
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              <a
                :href="href"
                class="verification-dropdown"
                @click="navigate"
              >Trash</a>
            </li>
          </router-link>
          <router-link
            v-if="isFreydaUser"
            v-slot="{ href, navigate, isActive, isExactActive }"
            to="/qa"
            custom
          >
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              <a
                :href="href"
                class="verification-dropdown"
                @click="navigate"
              >Manual QA</a>
            </li>
          </router-link>
        </div>
      </div>
      <router-link
        v-if="isFreydaUser"
        v-slot="{ href, navigate, isActive, isExactActive }"
        to="/entities"
        custom
      >
        <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
          <link-icon
            size="1.25x"
            class="nav-icon"
            :stroke="isExactActive ? 'black' : 'white'"
          />
          <a
            :href="href"
            @click="navigate"
          >Entities</a>
        </li>
      </router-link>
      <div
        @mouseover="settingsHoverOver"
        @mouseleave="settingsHoverLeave"
      >
        <router-link
          v-if="canSeeUsersSettings"
          v-slot="{ href, navigate, isActive }"
          to="/settings"
          custom
        >
          <li :class="[isActive && 'router-link-active', isActive && 'router-link-exact-active']">
            <settings-icon
              size="1.25x"
              class="nav-icon"
              :stroke="isActive ? 'black' : 'white'"
            />
            <a
              :href="href"
              @click="navigate"
            >Settings</a>
          </li>
        </router-link>
        <div
          v-if="settingsHover"
          class="dropdown-menu-animation"
        >
          <router-link
            v-slot="{ href, navigate, isActive, isExactActive }"
            to="/settings/users"
            custom
          >
            <li :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              <a
                :href="href"
                class="verification-dropdown"
                @click="navigate"
              >Users</a>
            </li>
          </router-link>
        </div>
      </div>
    </ul>
    <div class="nav-end">
      <a
        class="sign-out"
        @click="signOut"
      >Sign out</a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  FolderIcon,
  PieChartIcon,
  UploadIcon,
  LinkIcon,
  SettingsIcon,
} from '@zhuowenli/vue-feather-icons';
import Badge from '@/components/general/Badge.vue';

export default {
  name: 'Nav',
  components: {
    FolderIcon,
    PieChartIcon,
    UploadIcon,
    LinkIcon,
    SettingsIcon,
    Badge,
  },
  data() {
    return {
      unverifiedDocumentCount: null,
      qaDocumentCount: null,
      verificationHover: false,
      settingsHover: false,
      canSeeUsersSettings: false,
    };
  },
  computed: {
    isFreydaUser() {
      return this.$store.state.authenticate.cognitoUser
        .signInUserSession.idToken.payload.email.endsWith('@freyda.io');
    },
  },
  async created() {
    this.$log.debug('Initializing navbar');
    const response = await (this.init())
      .catch((e) => {
        this.$log.error(e);
        this.toast.error('Error fetching documents');
      });
    this.unverifiedDocumentCount = response.unverifiedDocumentCount;
    this.qaDocumentCount = response.qaDocumentCount;
    this.canSeeUsersSettings = await (this.checkPermission('CAN_LIST_USERS'));
  },
  methods: {
    ...mapActions({
      init: 'navigation/init',
      logout: 'authenticate/logout',
      checkPermission: 'localisation/checkPermission',
    }),
    signOut() {
      this.logout();
      this.$router.push({ name: 'SignIn' });
    },
    verificationHoverOver() {
      this.verificationHover = true;
    },
    verificationHoverLeave() {
      this.verificationHover = false;
    },
    settingsHoverOver() {
      this.settingsHover = true;
    },
    settingsHoverLeave() {
      this.settingsHover = false;
    },
  },
};
</script>

<style scoped>

</style>
