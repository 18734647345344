export default () => ([
  {
    id: 1,
    documentType: 'am_performance',
    fields: [
      'Performance Report 1',
      '30 May 2021',
      'Purple Field Capital',
      'Flatline Nash', '17 Apr 2021',
      'Frank Wattson',
      'DOCUMENT_CLIENT_VERIFIED',
      'Josh Smith',
    ],
  },
  {
    id: 2,
    documentType: 'am_capital_call',
    fields: [
      'Capital Call 1',
      '2 June 2021',
      'Thunderdome',
      'Sash Hemlock',
      '3 Apr 2021',
      'John Kraber',
      'DOCUMENT_PROCESSED',
      'Josh Smith'],
  },
  {
    id: 3,
    documentType: 'am_capital_call',
    fields: [
      'Capital Call 2',
      '3 June 2021',
      '2Spines',
      'Sash Hemlock',
      '3 Apr 2021',
      'John Kraber',
      'DOCUMENT_CLIENT_VERIFIED',
      'Loba Letsgo',
    ],
  },
  {
    id: 4,
    documentType: 'am_capital_call',
    fields: [
      'Zapital Call 3',
      '4 June 2021',
      '2Spines',
      'Sash Hemlock',
      '3 Apr 2021',
      'John Kraber',
      'DOCUMENT_CLIENT_TOUCHED',
      'Loba Letsgo',
    ],
  },
  {
    id: 5,
    documentType: 'am_performance',
    fields: [
      'Performance Report 2',
      '30 May 2022',
      'Purple Field Capital',
      'Flatline Nash', '17 Apr 2021',
      'Frank Wattson',
      'DOCUMENT_CLIENT_VERIFIED',
      'Johno Smith',
    ],
  },
]);
