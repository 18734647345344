import { getMappingFor, getMappingFrom } from './mappings';

const verifyStatusMapping = {
  DOC_UNVERIFIED: {
    backend: 'DOCUMENT_UNVERIFIED',
    display: 'Not Verified',
  },
  DOC_EDITED: {
    backend: 'DOCUMENT_CLIENT_TOUCHED',
    display: 'Document edited',
  },
  DOC_VERIFIED: {
    backend: 'DOCUMENT_CLIENT_VERIFIED',
    display: 'Document verified',
  },
};

const verifyStatusMappingFromBackend = getMappingFrom(verifyStatusMapping, 'backend');
const verifyStatusMappingToBackend = getMappingFor(verifyStatusMapping, 'backend');
const verifyStatusToDisplay = getMappingFor(verifyStatusMapping, 'display');

export {
  verifyStatusMapping,
  verifyStatusMappingFromBackend,
  verifyStatusMappingToBackend,
  verifyStatusToDisplay,
};
