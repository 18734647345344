<template>
  <div :class="customClass">
    <input
      :class="{'text-input': true, 'input-no-value': showNoValueStyling && !valueIsSet }"
      type="text"
      :value="modelValue"
      :required="required"
      :placeholder="placeholder"
      autocomplete="off"
      @input="updateValue($event.target.value)"
      @blur="onBlur"
      @focus="onFocus"
    >
  </div>
</template>

<script>
import isString from '../../store/helpers/isString';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    customClass: {
      type: [Object, Array],
      default: () => ({}),
    },
    modelValue: {
      validator: (propValue) => isString(propValue) || !Number.isNaN(propValue) || propValue === null,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showNoValueStyling: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['focus', 'blur', 'update:modelValue'],
  computed: {
    valueIsSet() {
      return !(this.modelValue === '' || this.modelValue === null);
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('update:modelValue', value);
    },
    onBlur(e) {
      this.$emit('blur', e);
    },
    onFocus(e) {
      this.$emit('focus', e);
    },
  },
};
</script>
