/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { logger } from '@/store/logger';
import { ActionContext, Commit, Dispatch } from 'vuex';
import { InterDocumentEntity } from '@/components/entities/types';
import {
  storeState, storeStateGetters, storeStateMutations, storeStateActions, allowedStates,
} from '../helpers/storeState';
import {
  EntityRelationshipsFilter, EntityRelationshipsPage, Getters, State,
} from './types';
import Api from '../helpers/api';

const MAX_RELATED_ENTITIES_PER_PAGE = 20;

const initialState = (): State => ({
  ...storeState,
  filter: {},
  orderBy: null,
  relatedEntitiesPage: null,
  offset: 0,
  entity: null,
});

const storeGetters: Getters = {
  ...storeStateGetters,
  relatedEntitiesPage: (state: State) => state.relatedEntitiesPage,
  relatedEntitiesPageIndex: (state: State) => (state.offset > 0
    ? (state.offset / MAX_RELATED_ENTITIES_PER_PAGE) : 0),
  totalPages: (state: State) => (state.relatedEntitiesPage
    ? Math.ceil(state.relatedEntitiesPage.totalEntityCount / MAX_RELATED_ENTITIES_PER_PAGE)
    : null),
  isCurrentPageLastPage: (state: State) => {
    if (!state.relatedEntitiesPage) {
      return false;
    }
    const pageIndex = state.offset > 0 ? (state.offset / MAX_RELATED_ENTITIES_PER_PAGE) : 0;
    const totalPages = Math.ceil(
      state.relatedEntitiesPage.totalEntityCount / MAX_RELATED_ENTITIES_PER_PAGE,
    );
    return pageIndex + 1 === totalPages;
  },
  filter: (state: State) => state.filter,
  offset: (state: State) => state.offset,
  orderBy: (state: State) => state.orderBy,
  entityId: (state: State) => state.entity?.interDocumentEntityId ?? null,
  entityDisplayNames: (state: State) => state.entity?.fins.map((f) => f.display) ?? [],
};

const store = {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: storeGetters,
  mutations: {
    ...storeStateMutations,
    SET_RELATED_ENTITIES_PAGE(state: State, page: EntityRelationshipsPage) {
      state.relatedEntitiesPage = page;
      logger.debug('relatedEntitiesPage has been updated', state.relatedEntitiesPage);
    },
    SET_FILTER(state: State, filter: EntityRelationshipsFilter) {
      state.filter = filter;
      logger.debug('relationships filter has been updated', state.filter);
    },
    SET_OFFSET(state: State, offset: number) {
      state.offset = offset;
    },
    SET_ORDER_BY(state: State, orderBy: string) {
      state.orderBy = orderBy;
    },
    SET_ENTITY(state: State, entity: InterDocumentEntity) {
      state.entity = entity;
      logger.debug('entity has been set to ', entity);
    },
    RESET(state: State) {
      logger.debug('Reseting state of relationships store');
      Object.assign(state, initialState());
    },
  },

  actions: {
    ...storeStateActions,
    init: async ({ commit, getters, dispatch }: { commit: Commit, getters: Getters, dispatch: Dispatch }, { entity, filter = {} }: { entity: InterDocumentEntity, filter: EntityRelationshipsFilter }): Promise<void> => {
      try {
        commit('RESET');
        commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
        logger.debug('Initializing entity relationships store', entity, filter);
        commit('SET_FILTER', filter);
        commit('SET_ENTITY', entity);
        const response = await dispatch('getRelatedEntities', {
          entityId: getters.entityId, offset: getters.offset, filters: getters.filter, orderBy: getters.orderBy,
        });
        logger.debug('Got relationships: ', response);
        commit('SET_RELATED_ENTITIES_PAGE', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    // NOTE: Related entities are PAGINATED.
    getRelatedEntities: async (
      { rootGetters }: any,
      {
        entityId, offset, filters, orderBy = null, maxPageSize = MAX_RELATED_ENTITIES_PER_PAGE,
      }: any,
    ) => {
      logger.debug('Fetching related entities to entity id:', entityId, filters);
      const relatedEntitiesFilters = { ...filters };
      let queryStringExtension = null;
      if (relatedEntitiesFilters.displayNames) {
        queryStringExtension = relatedEntitiesFilters.displayNames.map((str: string) => `displayName=${encodeURIComponent(str)}`).join('&');
        logger.debug('Converted display name filter to query string', queryStringExtension);
        delete relatedEntitiesFilters.displayNames;
      }
      return (new Api(process.env, rootGetters['authenticate/idToken']))
        .get(`inter-document-entities/${entityId}/relationships-overview${queryStringExtension ? `?${queryStringExtension}` : ''}`, {
          offset, ...orderBy && { orderBy }, maxPageSize, ...relatedEntitiesFilters,
        } as any);
    },

    sortRelatedEntities: async (
      { dispatch, commit, getters }: ActionContext<State, any>,
      { orderBy = null }: { orderBy: string | null },
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Sorting related entities - ', orderBy);
      try {
        commit('SET_ORDER_BY', orderBy);
        const response = await dispatch('getRelatedEntities', {
          entityId: getters.entityId, offset: getters.offset, filters: getters.filter, orderBy: getters.orderBy,
        });
        commit('SET_RELATED_ENTITIES_PAGE', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    refreshCurrentPage: async (
      { getters, commit, dispatch }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Re-fetching the current page of related entities');
      try {
        const response = await dispatch('getRelatedEntities', {
          entityId: getters.entityId, offset: getters.offset, filters: getters.filter, orderBy: getters.orderBy,
        });
        commit('SET_RELATED_ENTITIES_PAGE', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    filterRelatedEntities: async (
      { dispatch, commit, getters }: ActionContext<State, any>,
      filter: EntityRelationshipsFilter,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      commit('SET_OFFSET', 0);
      logger.debug('Filtering entities - the offset is set to 0');
      try {
        commit('SET_FILTER', filter);
        const response = await dispatch('getRelatedEntities', {
          entityId: getters.entityId,
          offset: getters.offset,
          filters: getters.filter,
          orderBy: getters.orderBy,
        });
        commit('SET_RELATED_ENTITIES_PAGE', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        logger.debug('Failed to filter related entities', e);
        throw e;
      }
    },

    nextPage: async (
      { getters, dispatch, commit }: ActionContext<State, any>,
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      const disableNext = getters.isCurrentPageLastPage;
      // A null token is the token of the first page - prevent execution to avoid a loop.
      if (disableNext) {
        logger.debug('The current page is the last page, no update will be made as next page does not exist');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return;
      }
      commit('SET_OFFSET', getters.offset + MAX_RELATED_ENTITIES_PER_PAGE);
      logger.debug('Fetching the next page of related entities - offset has been updated to ', getters.offset);
      try {
        const response = await dispatch('getRelatedEntities', {
          entityId: getters.entityId, offset: getters.offset, filters: getters.filter, orderBy: getters.orderBy,
        });
        commit('SET_RELATED_ENTITIES_PAGE', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },
    prevPage: async ({ getters, dispatch, commit }: ActionContext<State, any>) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      if (getters.entitiesPageIndex === 0) {
        logger.debug('The current page is the first page, no update will be made as previous page does not exist');
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
        return;
      }
      commit('SET_OFFSET', getters.offset - MAX_RELATED_ENTITIES_PER_PAGE);
      logger.debug('Fetching the previous page of related entities - offset has been updated to ', getters.offset);
      try {
        const response = await dispatch('getRelatedEntities', {
          entityId: getters.entityId, offset: getters.offset, filters: getters.filter, orderBy: getters.orderBy,
        });
        commit('SET_RELATED_ENTITIES_PAGE', response);
        commit('SET_STORE_STATUS', allowedStates.IS_READY);
      } catch (e) {
        commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
        throw e;
      }
    },

    deleteRelationships: async (
      { rootGetters, dispatch, commit }: ActionContext<State, any>,
      relationshipsIds: string[],
    ) => {
      commit('SET_STORE_STATUS', allowedStates.IS_LOADING);
      logger.debug('Deleting entities with the following interdocument ids: ', relationshipsIds);
      return new Api(process.env, rootGetters['authenticate/idToken'])
        .post('interdocumentrelationship/delete', { uuids: relationshipsIds })
        .then(async () => {
          logger.debug(`Entity relationships ${relationshipsIds} deleted successfully`);
          await dispatch('refreshCurrentPage');
          commit('SET_STORE_STATUS', allowedStates.IS_READY);
        })
        .catch((e) => {
          logger.error('Failed to delete entity relationships: ', relationshipsIds);
          commit('SET_STORE_STATUS', allowedStates.IS_ERRORING);
          throw e;
        });
    },
  },
};

export default store;
